 <!--
  * @Description: 
  * @Version: 1.0
  * @Autor: in hengqi by mengze 
  * @Date: 2021-06-04 18:01:02
 * @LastEditors: Seven
 * @LastEditTime: 2022-08-31 10:03:14
 -->
 <template>
  <div class="sign-up">
    <!-- <p>学员报名</p> -->
    <div class="signup-info">
      <el-form
        :model="signUpForm"
        :rules="signUpFormRules"
        label-position="right"
        ref="signUpForm"
      >
        <div class="student-area margin-auto">
          <h2 class="sub-title">成交信息</h2>
          <div>
            <el-row :gutter="24">
              <el-col
                :span="4"  
                v-for="(item, index) in makeList"
                :key="index"
                style="margin-bottom: 20px"
              >
                <v-autocomplete
                  v-if="item.value === 'isFourSix'"
                  v-model="transactionInfo[item.value]"
                  :items="[
                    { id: 1, name: '是' },
                    { id: 0, name: '否' },
                  ]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="'是否46分成'"
                  outlined
                  item-text="name"
                  item-value="id"
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                  @change="isfoursixChange"

                ></v-autocomplete>

                <v-autocomplete
                  v-else-if="item.value === 'lecturerAssigned'"
                  v-model="transactionInfo[item.value]"
                  :items="[
                    { id: true, name: '是' },
                    { id: false, name: '否' },
                  ]"
                  :label="'是否外派'"
                  outlined
                  disabled
                  item-text="name"
                  item-value="id"
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                ></v-autocomplete>

                <v-autocomplete
                  v-else-if="item.value === 'promotionForm'"
                  v-model="transactionInfo[item.value]"
                  :items="[
                    { id: '招生协助', name: '招生协助' },
                    { id: '合伙人自主', name: '合伙人自主' },
                  ]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="'促单形式'"
                  outlined
                  :disabled="!transactionInfo['economicFlowPartner'] && !(transactionInfo.channelsTypeName == '财经流量合伙人')"
                  item-text="name"
                  item-value="id"
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                ></v-autocomplete>
                <v-text-field
                  v-else-if="item.value === 'transIntroduceType'"
                  v-model="recommandTypeMap[transactionInfo[item.value]]"
                  :label="item.label"
                  hide-details="auto"
                  outlined
                  readonly
                  :dense="true"
                  :height="32"
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="transactionInfo[item.value]"
                  :label="item.label"
                  hide-details="auto"
                  outlined
                  readonly
                  :dense="true"
                  :height="32"
                ></v-text-field>
              </el-col>
              <el-col :span="4" style="margin-bottom: 20px">
                <v-autocomplete
                  v-model="transactionInfo['assistDealCrmUserId']"
                  :items="assistDealList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="'协助成交人员'"
                  outlined
                  item-text="assistDealUserName"
                  item-value="assistDealCrmUserId"
                  :dense="true"
                  :height="32"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                  :disabled="transactionInfo.isRecommand == 1"
                ></v-autocomplete>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 学员信息   -->
        <div class="student-area margin-auto">
          <h2 class="sub-title">
            学员信息<span
              style="fontweight: normal; fontsize: 12px; color: red"
            >
              (学习账号用于app的学习与签约接收验证码，请正确填写)</span
            >
          </h2>
          <div>
            <el-row :gutter="24">
              <el-col :span="4">
                <el-form-item prop="stuMoblie" class="inline-formitem">
                  <v-text-field
                    :disabled="disablePhoneNumber"
                    v-model.trim="signUpForm.stuMoblie"
                    label="学习账号"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    @blur="getCustomeruser"
                  ></v-text-field>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="name" class="inline-formitem">
                  <v-text-field
                    v-model.trim="signUpForm.name"
                    label="学员姓名"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                  ></v-text-field>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  prop="idCard"
                  class="inline-formitem idCardBigBox"
                >
                  <v-text-field
                    v-model.trim="signUpForm.idCard"
                    label="身份证号"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    @blur="idcardBlur"
                  ></v-text-field>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="education" class="inline-formitem">
                  <dict-select code="EDUCATION_TYPE">
                    <v-select
                      v-model="signUpForm.education"
                      slot-scope="props"
                      :items="props.dictData"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="学员学历"
                      item-text="label"
                      item-value="value"
                      outlined
                      :dense="true"
                      :height="32"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                    ></v-select>
                  </dict-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="emergencyContact" class="inline-formitem idCardBigBox">
                  <v-text-field
                    v-model.trim="transactionInfo.emergencyContact"
                    label="紧急联系人"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="42"
                  ></v-text-field>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item prop="emergencyContactPhone" class="inline-formitem idCardBigBox">
                  <v-text-field
                    v-model.trim="transactionInfo.emergencyContactPhone"
                    label="紧急联系人电话"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="42"
                  ></v-text-field>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="line"></div>

        <!-- 商品信息 -->
        <div class="goods-area margin-auto">
          <section class="choice-size">
            <h2 class="sub-title">商品信息</h2>

            <el-row :gutter="8">
              <el-col :span="8">
                <el-form-item prop="goodsName">
                  <v-text-field
                    style="width: 100%; display: inline-block"
                    v-model="signUpForm.goodsName"
                    label="选择商品"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    readonly
                    @click.native="onChoiceGoods"
                  >
                  </v-text-field>
                </el-form-item>
              </el-col>
              <el-col :span="8" style="line-height: 40px">
                <a
                  type="error"
                  v-show="currentGoods.statusOnlineSell == 2"
                  @click="previewGoods(currentGoods.goodsId)"
                  style="margin: 0 12px"
                  >预览</a
                >
                <a
                  type="error"
                  :disabled="!signUpForm.goodsName"
                  @click="removeChoiceGoods"
                  >重置</a
                >
              </el-col>
            </el-row>

            <!-- ******************************************************************* -->
            <!-- 用于成考，网教-单品 -->
            <template v-if="this.ctr.showCkSingleOption">
              <el-row :gutter="8" v-if="showYxOption ==true">
                <el-col :span="8">
                    <schooleTree 
                      ref="deptsys"
                      :data="findSchoolList"
                      @update:select="selectSchoolNcid"/>
                  </el-col>
              </el-row>
              <br />
              <el-row :gutter="24">
                <el-col :span="6" v-if="showYxOption == true">
                    <el-form-item prop="enrollingMajorId" style="margin-bottom: 12px">
                        <v-autocomplete
                          style="width: 250px; margin-right: 12px"
                          v-model="ckSingle.form.enrollingMajorId"
                          :items="this.ckSingle.enrollingMajorList"
                          :menu-props="{ bottom: true, offsetY: true }"
                          label="报考专业"
                          outlined
                          item-text="majorName"
                          item-value="majorId"
                          :dense="true"
                          :height="32"
                          :clearable="true"
                          clear-icon="$clear"
                          hide-details="auto"
                          no-data-text="暂无数据"
                          class="normal-select"
                          @change="selectCkSingleProfessional"
                        ></v-autocomplete>
                      </el-form-item>
                </el-col>
              </el-row>
              <el-form-item v-if="showYxOption == false">
                <v-text-field
                  style="width: 250px"
                  v-model="currentGoods.enrollingSchoolName"
                  label="报考院校"
                  hide-details="auto"
                  outlined
                  :dense="true"
                  :height="32"
                  readonly
                >
                </v-text-field>
              </el-form-item>
              <div style="display: flex">
                <el-form-item prop="enrollingMajorId" style="margin-bottom: 12px" v-if="showYxOption == false">
                  <v-autocomplete
                    style="width: 250px; margin-right: 12px"
                    v-model="ckSingle.form.enrollingMajorId"
                    :items="this.ckSingle.enrollingMajorList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="报考专业"
                    outlined
                    item-text="majorName"
                    item-value="id"
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectCkSingleProfessional"
                  ></v-autocomplete>
                </el-form-item>
                <el-form-item prop="enrollingGradationId" style="margin-bottom: 12px">
                  <v-autocomplete
                    style="width: 250px"
                    v-model="ckSingle.form.enrollingGradationId"
                    :items="this.ckSingle.enrollingGradationList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="报考学级"
                    outlined
                    item-text="gradationYear"
                    item-value="id"
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectCkSingleGradation"
                  ></v-autocomplete>
                </el-form-item>
              </div>
              <div v-if="repeat" style="color: red;padding-bottom: 10px">{{ ckMessage }}</div>
              <div style="display: flex">
                <el-form-item v-if="ckSingle.goodsPrice">
                  <v-text-field
                    style="width: 250px"
                    v-model="ckSingle.goodsPrice"
                    label="商品价格(元)"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    readonly
                  >
                  </v-text-field>
                </el-form-item>
                <el-form-item
                  v-if="ckSingle.effectiveMonth"
                  style="margin-left: 12px"
                >
                  <v-text-field
                    v-if="ckSingle.effectiveMonth"
                    style="width: 250px"
                    :value="ckSingle.effectiveMonth"
                    label="学习有效期(月)"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    readonly
                  >
                  </v-text-field>
                </el-form-item>
              </div>
              <el-form-item
                v-if="currentGoods.xlType != 3 ?xlType == 2?ckSingle.courseList.length:true:false"
                prop="choiceClass"
                class="choiceClass"
              >
                <h2>课程清单</h2>
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '20px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  highlight-current-row
                  style="width: 800px"
                  header-cell-class-name="normal-table-header"
                  :data="ckSingle.courseList"
                >
                  <el-table-column
                    :show-overflow-tooltip="true"
                    label="课程"
                    prop="courseName"
                  />
                  <el-table-column
                    :show-overflow-tooltip="true"
                    label="类型"
                    prop="type"
                  />
                  <el-table-column
                    :show-overflow-tooltip="true"
                    label="上课形式"
                    prop="classForm"
                    width="125"
                  />
                </el-table>
              </el-form-item>

              <!-- 第二志愿 -->
              <template v-if="xlType == 2">
                <el-form-item>
                  <h2>第二志愿（选填）</h2>
                </el-form-item>
                <el-form-item prop="goodsName" v-if="disabledsp">
                  <v-text-field
                    style="width: 500px"
                    v-model="currentGoods_second.goodsName"
                    label="选择商品"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    readonly
                    @click.native="onChoiceGoods_second"
                  >
                  </v-text-field>
                </el-form-item>
                 <el-row :gutter="24" v-if="showYxOption2 ==true">
                  <el-col :span="8">
                        <schooleTree 
                          ref="deptsystwo"
                          :data="findtwoSchoolList"
                          @update:select="selectSchoolNcidtwo"/>
                      </el-col>
                </el-row>
                <br />
                <el-form-item v-if="showYxOption2 ==false">
                  <v-text-field
                    style="width: 500px"
                    v-model="currentGoods_second.enrollingSchoolName"
                    label="报考院校"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    readonly
                  >
                  </v-text-field>
                </el-form-item>
                <el-form-item prop="enrollingMajorId" v-if="showYxOption2 == true">
                  <v-autocomplete
                    style="width: 500px; margin-right: 12px"
                    v-model="ckSingle.form.enrollingMajorId_second"
                    :items="this.ckSingle.enrollingMajorList_second"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="报考 专业"
                    outlined
                    item-text="majorName"
                    item-value="majorId"
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectCkSingleProfessional_second"
                  ></v-autocomplete>
                </el-form-item>
                <el-form-item prop="enrollingMajorId" v-if="showYxOption2 == false">
                  <v-autocomplete
                    style="width: 500px; margin-right: 12px"
                    v-model="ckSingle.form.enrollingMajorId_second"
                    :items="this.ckSingle.enrollingMajorList_second"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="报考专 业"
                    outlined
                    item-text="majorName"
                    item-value="id"
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectCkSingleProfessional_second"
                  ></v-autocomplete>
                </el-form-item>
              </template>
            </template>

            <!-- 用于以前的财经学员报名 -->
            <template v-if="this.ctr.showCjSingleOption">
              <el-row :gutter="8">
                <el-col :span="8">
                  <el-form-item prop="spec" style="margin-bottom: 10px">
                    <v-text-field
                      style="width: 100%"
                      v-model="signUpForm.spec"
                      label="选择班型"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="32"
                      readonly
                      @click.native="onChoiceGoodsSpec"
                    >
                    </v-text-field>
                  </el-form-item>
                </el-col>
                <el-col :span="24" v-if="repeat">
                  <div style="color: red;padding-bottom: 10px">学员已报名该班型，请勿重复报名</div>
                </el-col>
              </el-row>
              <template v-if="employmentShow">
                <span style="font-size: 14px;color: #333;">就业保底底金</span>
                <el-row :gutter="8" style="margin-top: 12px">
                  <el-col :span="4">
                    <el-form-item prop="jobLowestSalaryUnderCollege">
                      <v-text-field
                        style="width: 100%"
                        v-model="signUpForm.jobLowestSalaryUnderCollege"
                        label="大专以下(元/月)"
                        hide-details="auto"
                        outlined
                        :dense="true"
                        :height="32"
                      >
                      </v-text-field>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item prop="jobLowestSalaryUpCollege">
                      <v-text-field
                        style="width: 100%"
                        v-model="signUpForm.jobLowestSalaryUpCollege"
                        label="大专及大专以上(元/月)"
                        hide-details="auto"
                        outlined
                        :dense="true"
                        :height="32"
                      >
                      </v-text-field>
                    </el-form-item>
                  </el-col>
                </el-row>            
              </template>
    
              <el-form-item
                prop="specTable"
                v-if="currentGoods.goodsTemplate != 6"
              >
                <!-- 规格表格 -->
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '20px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  highlight-current-row
                  style="width: 100%"
                  header-cell-class-name="normal-table-header"
                  :data="goodsSpecTable"
                >
                  <template v-for="item of goodsColumns">
                    <el-table-column
                      :show-overflow-tooltip="true"
                      v-if="item.prop === 'goodsDiscountPrice'"
                      :prop="item.prop"
                      :key="item.prop"
                      :label="item.label"
                    >
                      <template v-slot:default="{ row }">
                        <p
                          v-if="
                            row.goodsDiscountPrice != '' &&
                            row.goodsDiscountPrice != '--' &&
                            row.goodsDiscountPrice != undefined
                          "
                        >
                          {{
                            (row.goodsPrice - row.goodsDiscountPrice).toFixed(2)
                          }}
                        </p>
                        <p v-else>0.00</p>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :show-overflow-tooltip="true"
                      v-else
                      :width="item.width || '120px'"
                      :key="item.label"
                      :label="item.label"
                      :prop="item.prop"
                    />
                  </template>
                </el-table>
              </el-form-item>
              <el-form-item
                prop="specTable"
                v-if="currentGoods.goodsTemplate == 6"
              >
                <div style="color: #666">
                  <span
                    >商品价格: ¥{{
                      (goodsSpecTable[0] && goodsSpecTable[0].goodsPrice) || 0.0
                    }}</span
                  >
                </div>
              </el-form-item>
              <el-form-item
                prop="specTable"
                v-if="currentGoods.goodsTemplate == 6"
              >
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '20px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  highlight-current-row
                  style="width: 100%"
                  header-cell-class-name="normal-table-header"
                  :data="comComGoodsInfoList"
                >
                  <template v-for="item of cjComComGoodsColumns">
                    <el-table-column
                      :show-overflow-tooltip="true"
                      :width="item.width || '120px'"
                      :key="item.label"
                      :label="item.label"
                      :prop="item.prop"
                    />
                  </template>
                </el-table>
              </el-form-item>

              <!-- 财经课程清单 -->
              <el-form-item prop="choiceClass" class="choiceClass">
                <span class="litter-title">课程清单</span>
                <selcetionClass
                  :mealGroupData="mealGroupData"
                  :type="currentGoods.type"
                  :oneItem="oneItem"
                  ref="selcetionClass"
                />
              </el-form-item>
            </template>
            <!-- ******************************************************************* -->
          </section>
        </div>

        <div class="line"></div>
        <div class="bottom-area margin-auto">
          <h2 class="shouleReceive">收款信息</h2>
          <el-row :gutter="24" style="padding-top: 20px">
            <el-col :span="8">
              <v-text-field
                :value="newGoodsPrice"
                label="商品价格"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
                readonly
              >
              </v-text-field>
            </el-col>
            <el-col :span="24" style="margin: 20px 0">
              <el-row :gutter="20">
                <el-col :span="4">
                  <v-autocomplete
                    v-model="discountType"
                    :items="discountInfoList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="'商品优惠'"
                    outlined
                    item-text="activityName"
                    item-value="id"
                    :dense="true"
                    :height="32"
                    :clearable="true"
                    :clear-on-select="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    @change="selectDiscountInfo"
                    no-data-text="暂无数据"
                  ></v-autocomplete>
                </el-col>
                <el-col :span="4">
                  <v-text-field
                    v-model="discountPrice"
                    label="商品优惠金额"
                    :disabled="!wxTradeOrderDiscountVOList.length"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    @input="inputDiscountPrice"
                  >
                  </v-text-field>
                </el-col>
                <el-col :span="4" v-if="discountNotuse.length"
                  ><div @click="disountsShow = true" style="line-height: 32px; fontsize: 14px; color: #f00;cursor: pointer;">
                    <i class="el-icon-warning-outline"></i>不可用优惠
                  </div></el-col
                >
                <el-col v-if="wxTradeOrderDiscountVOList.length" :span="24"
                  ><div style="height: 24px; fontsize: 14px; color: #f00">
                    {{ discountTip }}
                  </div></el-col
                >
              </el-row>
            </el-col>
            <el-col :span="8">
              <el-row>
                <el-col :span="24">
                  <v-text-field
                    :value="shouldPay"
                    label="应收金额(元)"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="32"
                    readonly
                  >
                  </v-text-field>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
        <div class="line"></div>
        <div class="colllection-info-wrap choice-course-area receiveItem">
          <h2 class="sub-title">收费项目</h2>
          <section class="colllectionInfoBox">
            <receiveItemTable
              :columns="receiveItemColumns"
              :tableData="receiveItemData"
              :showSummary="true"
              :summaryMethod="receiveItemSummaryMethod"
            />
          </section>
        </div>
      </el-form>
    </div>

    <div class="action-area">
      <el-button size="medium" :gap="18" @click="gobackclick">取消</el-button>
      <el-button
        size="medium"
        type="primary"
        @click="showSureData('signUpForm')"
        :disabled="!currentGoods.goodsId"
        >提交</el-button
      >
      <!-- <el-button
        size="medium"
        type="primary"
        @click="showSureData('signUpForm')"
        >提交</el-button
      > -->
    </div>

    <!-- 正常的选择商品 -->
    <goods-modal1
      :visible.sync="goodsDrawerVisible"
      :goodsForm="goodsForm"
      selectGoodsType="2"
      :goodsFormRules="goodsFormRules"
      :goodsFormItems="goodsFormItems"
      :schoolId="this.transactionInfo.ncCampusSchoolId"
      @on-ok="onGoodsDrawerOk"
      type='signup'
    />

    <!-- 第二志愿选择商品，只允许选择成考商品 -->
    <goods-modal1
      :visible.sync="goodsDrawerVisible_second"
      :goodsForm="goodsForm"
      :goodsFormRules="goodsFormRules"
      :goodsFormItems="goodsFormItems"
      :businessId="currentGoods.businessId"
      selectGoodsType="5"
      :enrollingMajorId="graduactionForm.enrollingMajorIdList[0]"
      :enrollingGradation="ckSingle.enrollingTime"
      :enrollingProductId="currentGoods.productId"
      @on-ok="onGoodsDrawerOk_second"
      type='signup'
    />

    <goods-specs-modal1
      :visible="gsVisible"
      :specTypeArr="specTypeArr"
      :specPriceArr.sync="specPriceArr"
      :specArray="specArray"
      :currentGoodsRank="currentGoodsRank"
      :specCountArr="specCountArr"
      @on-cancel="onGsCancel"
      @onSpecModalOk="onSpecModalOk"
    />

    <confirm-order-modal1
      :visible="showConfirmModal"
      :goodsPic="currentGoods.smallPic"
      :goodsName="currentGoods.goodsName"
      :specificationName="signUpForm.spec"
      :orderMoney="totalPrice"
      @on-ok="getCreate"
      @on-close="handleConfirmModal(false)"
    />

    <!-- 提交时确认订单弹窗 -->
    <sure1
      :visible="sureVisible"
      showTitle="确认报名单"
      :sureData="sureData"
      :loading="submitLoading"
      @on-cancel="onSureCancel"
      @on-ok="onSureOk"
    />

    <HqModal
      :visible="disountsShow"
      title="不可用优惠"
      @on-cancel="disountsShow = false"
      width="480"
    >
      <div class="discount-modal" v-for="item of discountNotuse" :key="item.id">
        <div>{{ item.activityName }}</div>
        <div>优惠¥0.00~ ¥{{ item.preferentialAmount }}.00</div>
        <div>
          <div>学员不符合优惠条件</div>
          <div>{{ item.endTime }} 过期</div>
        </div>
      </div>
      <div slot="footer">
        <el-button
          size="small"
          type="primary"
          @click="disountsShow = false"
        >确认</el-button>
      </div>
    </HqModal>
    <!-- 没通过的弹窗 showTis-->
     <HqModal
      :visible="showTis"
      title="提交失败"
      @on-cancel="tijiaoProcancel"
      width="480"
    >
    <div><i icon="el-icon-warning" type="danger"></i>失败原因</div>
    <div class="tishiclass">
      <ul  
        v-for="(item, index) in tiforList"
        :key="index">
        <li>{{item}}</li>
      </ul>
    </div>
    <el-form
      :model="lingyuanForm"
      :rules="lingyuanFormRules"
      label-position="right"
      ref="form"
    >
      <el-form-item prop="lingyuan" class="Contextclass">
         <v-textarea
          v-model.trim="lingyuanForm.lingyuan"
          hide-details="auto"
          label="开通零元课程原因"
          placeholder="请输入开通零元课程原因,500字以内"
          outlined
          :dense="true"
          :height="120"
          maxlength="500"
          counter
        ></v-textarea>
        
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button
        size="small"
        @click="tijiaoProcancel"
      >取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="tijiaoProadd"
      >提交审批</el-button>
    </div>
    </HqModal>
  </div>
</template>
 
 <script>
import { mapGetters } from "vuex";
import axios from '@/utils/request';
import { WOKENBENCH_SIGNUP_TYPE, recommandTypeMap } from "../constants";
//用于金额计算
import { accAdd } from "@/utils/mathMethod";
//用于金额计算
import { addPriceFixed } from "@/utils/price-fixed";
import validate from "@/utils/validate";
import { getTransactionInfo, getDeptId, checkIdCard } from "api/customer";
import { addSignUpJudgeRecommend } from "api/order/change";
import { formatDate } from "@/utils/timeDate";
export default {
  name: "BusinessSignUp",
  data() {
    return {
      lingyuan: '',
      lingyuanForm: {
        lingyuan: ''
      },
      lingyuanFormRules: {lingyuan: [{ required: true, message: "此字段必填！", trigger: "change" }],},
      property: null,
      discountType: '',
      //优惠活动字段
      // activityId: 活动id,
      // discount:   优惠金额
      wxTradeOrderDiscountVOList: [],
      discountPrice: "",
      discountNewPrice: "",
      discountTip:"",
      discountInfoList: [],
      discountNotuse: [],
      disablePhoneNumber: false,
      //第二志愿的当前选中商品
      currentGoods_second: {},
      //成交信息
      transactionInfo: {},
      //提交按钮网络加载指示器
      submitLoading: false,
      //判断是不是套餐
      xlType: 1,
      //商品规格
      commodityIdList: [],
      commodityIdList_second: [],
      //商机id
      customerId: "",
      //多个<template>隐藏显示控制
      ctr: {
        showCjSingleOption: false,
        showCkSingleOption: false,
      },
      //财经组合套餐信息
      cjComComGoodsInfo: {},
      //此案经组合套餐信息列表
      comComGoodsInfoList: [],
      isCj: true, //决定了 businssInt值
      zkNewGoodsPrice: 0,
      zkGoodsDiscount: 0,

      ckSingle: {
        xlType: 2,
        xlType_second: 2,
        //商品id
        goodsId: "",
        goodsId_second: "",
        //商品价格
        goodsPrice: "",
        //报考院校
        enrollingSchoolName: "",
        enrollingSchoolName_second: "",
        //报考学级
        enrollingGradationDatasource: [],
        enrollingGradationDatasource_second: [],
        enrollingGradationList: [],
        //报考专业
        enrollingMajorList: [],
        enrollingMajorList_second: [],
        specificationPriceDataSource: [],
        specificationPriceDataSource_second: [],
        //课程清单
        courseList: [],
        form: {
          enrollingMajorId: "",
          enrollingGradationId: "",
          enrollingMajorId_second: "",
          enrollingGradationId_second: "",
        },
      },

      /***
       * 针对新加的学历
       */
      graduactionForm: {
        enrollingProvinceIdList: [], //报考省份名称集合
        enrollingProvinceNameList: [], //报考省份名称集合
        enrollingMajorIdList: [], //报考专业id集合
        enrollingMajorNameList: [], //报考专业名称集合
        enrollingSchoolIdList: [], //报考院校id集合
        enrollingSchoolNameList: [], //报考院校名称集合
        gradationIdList: [], //报考层次(1专科  2本科)
        enrollingTime: "", //报考时间(用于记录成考、网教报考学级)

        enrollingMajorIdList_second: [], //报考专业id集合
        enrollingMajorNameList_second: [], //报考专业名称集合
        enrollingSchoolIdList_second: [], //报考院校id集合
        enrollingSchoolNameList_second: [], //报考省份名称集合
        gradationIdList_second: [], //报考层次(1专科  2本科)
      },

      previousSpecIdArr: null,
      sureVisible: false, //提交时确认弹出
      sureData: {},
      receiveItemData: [], //收费项目

      // 报名大表单
      signUpForm: {
        id: "",
        name: "",
        idCard: "",
        goodsName: "",
        stuMoblie: "", //蓝鲸学员账号
        customerNum: "",
        discount: "",
        spec: "",
        teacherName: "",
        schoolName: "",
        userId: "",
        jobLowestSalaryUnderCollege: '',
        jobLowestSalaryUpCollege: '',
        education: ""
      },

      signUpFormRules: {
        //学员姓名
        name: [
          {
            required: true,
            validator: validate.validateName,
            trigger: "change",
          },
        ],
        //身份证号
        idCard: [
          { required: true, validator: validate.idCard, trigger: "change" },
        ],
        //学员手机号
        stuMoblie: [
          { required: true, validator: validate.digital, trigger: "change" },
        ],
        //学员学历
        education: [
          { required: true, message: "请选择学员学历！", trigger: "change" },
        ],
        //商品名称
        goodsName: [
          { required: true, message: "此字段必填！", trigger: "change" },
        ],
        //班型名称
        spec: [{ required: true, message: "此字段必填！", trigger: "change" }],
        jobLowestSalaryUnderCollege: [{ required: true, validator: validate.underCollege, trigger: "change" }],
        jobLowestSalaryUpCollege: [{ required: true, validator: validate.upCollege, trigger: "change" }],
      },

      //收支项
      receiveItemColumns: [
        { label: "收支项目名称", prop: "inoutProjectName", fixed: true },
        { label: "标准价格", prop: "standardPrice" },
        { label: "优惠额", prop: "discountAmount" },
        { label: "应收金额", prop: "receivable" },
      ],
      //商品规格
      goodsSpecTable: [],
      goodsColumns: [
        { label: "商品价格", prop: "goodsPrice" },
        { label: "会答总数", prop: "kuaidaTotal" },
        { label: "学习有效期（月）", prop: "effectiveMonth", width: "140px" },
        {
          label: "培训有效期（月）",
          prop: "trainEffectiveMonth",
          width: "140px",
        },
        { label: "复训期（月）", prop: "retrainingCount" },
        { label: "重读次数", prop: "restudyCount" },
        { label: "请假次数", prop: "leaveCount" },
        { label: "调班次数", prop: "shiftClassCount" },
        { label: "休学次数", prop: "suspendSchoolCount" },
        { label: "转校次数", prop: "shiftSchoolCount" },
      ],
      cjComComGoodsColumns: [
        { label: "班型", prop: "goodsName", width: "260px" },
        { label: "会答总数", prop: "kuaidaTotal", width: "140px" },
        { label: "学习有效期（月）", prop: "effectiveMonth", width: "140px" },
        {
          label: "培训有效期（月）",
          prop: "trainEffectiveMonth",
          width: "140px",
        },
        { label: "复训期（月）", prop: "retrainingCount", width: "140px" },
        { label: "重读次数", prop: "restudyCount", width: "140px" },
        { label: "请假次数", prop: "leaveCount", width: "140px" },
        { label: "调班次数", prop: "shiftClassCount", width: "140px" },
        { label: "休学次数", prop: "suspendSchoolCount", width: "140px" },
        { label: "转校次数", prop: "shiftSchoolCount", width: "140px" },
      ],
      //商品规格弹窗
      gsVisible: false,
      //商品选择弹窗
      goodsDrawerVisible: false,
      //第二志愿选择弹窗
      goodsDrawerVisible_second: false,

      // 商品搜索表单
      goodsForm: {
        productId: "", //产品
        goodsCategoryName: "", //分类名
        goodsCategoryId: "", //分类id
        goodsId: "", //商品ID
        goodsName: "", //商品名称
        // keywordsKey: "goodsId", //默认值
        // keywordsValue: "",
      },

      goodsFormRules: {},
      goodsFormItems: [
        {
          type: "select",
          label: "产品线",
          prop: "productId",
          options: [],
        },
        {
          type: "search",
          label: "商品分类",
          prop: "goodsCategoryName",
          placeholder: "请选择商品分类",
        },
      ],

      //商机信息
      customerInfo: {},
      // 商品规格类型数组
      specTypeArr: [],
      // 商品规格价格数组
      specPriceArr: [],
      specCountArr: [],

      //财经套餐分组信息
      mealGroupData: [],
      //财经单品的课程清单信息
      oneItem: [],

      collectionInfo: {
        // 选择的新转班商品售价
        newGoodsPrice: "0",
        // 原商品的售价->总已收金额
        oldGoodsPrice: "0",
        // 转班折扣 ，原商品的总退费金额
        discountPrice: 0,
        // 是否使用余额
        isBalancePay: "0",
        // 最后计算的价格，应收款
        finalPrice: "0",
      },

      // 当前用户选择的商品
      currentGoods: {
        goodsId: "",
      },

      // 商品总价
      totalPrice: 0,

      //课程为套餐时，选择的课程数组
      userSelectMealGroup: [],
      //商品规格的类型
      currentGoodsRank: 0,
      //存储第二行数组用作判断
      specArray: [],
      //确认报名单弹框
      showConfirmModal: false,

      //收支项列表
      inoutProjectList: [],
      //规格相关信息
      specificationPriceArr: [],

      // 成交信息
      makeList: [
        { label: "客户编号", value: "customerId" },
        { label: "商机编号", value: "ncCustomerUserId" },
        { label: "学员手机", value: "phoneNumber" },
        { label: "招生老师", value: "teacherRecruitName" },
        { label: "销售老师", value: "teacherSalesName" },
        { label: "成交校区", value: "dealSchoolName" },
        { label: "上课校区", value: "campusSchoolName" },
        { label: "转介绍", value: "isRecommandName" }, //是否是转介绍
        { label: "转介绍类型", value: "transIntroduceType" },
        { label: "转介绍老师", value: "teacherRecommandName" },
        { label: "公开课类型", value: "openClassType" },
        { label: "是否外派", value: "lecturerAssigned" },
        { label: "讲师", value: "teacherLecturerName" },
        { label: "流量经纪人", value: "economicFlowPartner" },
        { label: "流量合伙人", value: "flowPartner" },
        { label: "是否46分成", value: "isFourSix" }, //是否46分成
        { label: "促单形式", value: "promotionForm" },
        { label: "招生途径", value: "channelsTypeName" },
      ],
      assistDealList:[],//协助成交人员列表
      // 优惠劵新增4个字段
      activedParams: {},
      recommandTypeMap,
      marketInoutConfigSnapshotId: "",
      inoutConfigSnapshot: null,
      // 是否有就业协议
      employmentShow: false,
      // 不可用优惠弹窗
      disountsShow: false,
      // 成考网教是否复购
      repeat: false,
      ckMessage: "",
      // 商品定价状态
      statusPrice: "",
      // 是否可以修改学习账号
      isCanStudy: false,
      findSchoolList: [],
      showYxOption: false,
      majorList: [],
      // 第二志愿
      findtwoSchoolList: [],
      showYxOption2: false,
      canSelectMajor: false,
      disabledsp: false,
      tiforList: [],
      showTis: false,
      processParmas: {},
      isCheckIdCard: true
    };
  },
  components: {
    goodsSpecsModal1: () => import("../components/goods-specs-modal1"),
    goodsModal1: () => import("../components/goods-modal1"),
    confirmOrderModal1: () => import("../components/confirmOrderModal"),
    selcetionClass: () => import("./components/selcetionClass"),
    receiveItemTable: () => import("../components/receive-item-table"),
    sure1: () => import("../components/sure1"),
    dictSelect: () =>import( "components/dictSelect"),
    schooleTree: () => import("@/components/schooletree")
  },

  methods: {
    async tijiaoProadd() {
      let params = {
        orderData: this.processParmas,
        failMsgList: this.tiforList,
        reason: this.lingyuanForm.lingyuan,
        tyUserId:this.loginUser.tyUserId
      };

      console.log('params提交审批', params);
      const {code, data, msg} = await this.$fetch("third_processAdd",params)
      if (code == 200) {
        this.$message.success(msg)
        //  //是否免费商品
        // let isFreePay = this.shouldPay < 0.0001;
        // //是否是财经商品
        // let isCjGoods = `${this.currentGoods.businessId}` === "1";
        // this.toNextPage(isFreePay, isCjGoods, data.orderId);
        this.$router.push("/afterSale/order")
      } else {
        this.$message.error(msg)
      }
    },
    // 获取协助成交人员列表数据
    getAssistDealList() {
      const arr = [this.transactionInfo.crmDealSchoolDeptId]
      axios.post(`/qw_api/wb/biz/userExtend/getTeacherList?requireOld=false`, arr).then(response => {
        const res = response.data
        if(res.code === 0){
          this.assistDealList = res.result.map((item) => {
            return {
              assistDealNcUserId:item.ncUserPk,
              assistDealCrmUserId:item.userId,
              assistDealUserName:item.userName
            }
          })
        }else{
          this.$message.error(res.msg)
        }
      }).catch( () => {
        this.$message.error(error.msg)
      })
    },
    tijiaoProcancel() {
      this.showTis = false
    },
    isfoursixChange(val) {
      console.log('val',val);
      if (val == 1) {
        let form = {
            //商机id
            customerId: this.customerId,
            //来源，表示从哪个页面进来的；如果是商机详情页进来，则sourceId 可以为空。
            sourceId: this.sourceId ? this.sourceId : "0",
            registSource: this.registSource,
          };
          if(this.$route.query.id) {
            form.dealDeptId = this.$route.query.id
          }
          if(this.$route.query.phone) {
            form.phone = this.$route.query.phone
          }
        getTransactionInfo(form).then((res) => {
        if (res.code === 0) {
            this.transactionInfo.ncTeacherSalesUserId = res.result.ncTeacherSalesUserId || null;
            this.transactionInfo.crmTeacherSalesUserId = res.result.crmTeacherSalesUserId || null;
            this.transactionInfo.tyUserId = res.result.tyUserId || null;
            this.transactionInfo.teacherSalesName = res.result.teacherSalesName || null;
            this.transactionInfo.teacherSalesDeptName = res.result.teacherSalesDeptName || null;
            this.transactionInfo.crmTeacherSalesDeptId = res.result.crmTeacherSalesDeptId || null;
            this.transactionInfo.ncTeacherSalesDeptId = res.result.ncTeacherSalesDeptId || null;
            this.transactionInfo.emergencyContact = res.result.emergencyContact || null;
            this.transactionInfo.emergencyContactPhone = res.result.emergencyContactPhone || null;
          }
        });
      } else {
        this.transactionInfo.ncTeacherSalesUserId = null;
            this.transactionInfo.crmTeacherSalesUserId = null;
            this.transactionInfo.tyUserId = null;
            this.transactionInfo.teacherSalesName = null;
            this.transactionInfo.teacherSalesDeptName = null;
            this.transactionInfo.crmTeacherSalesDeptId = null;
            this.transactionInfo.ncTeacherSalesDeptId = null;
            this.transactionInfo.emergencyContact = res.result.emergencyContact || null;
            this.transactionInfo.emergencyContactPhone = res.result.emergencyContactPhone || null;
      }
    },
    /***
     * 优惠金额判断
     */
    inputDiscountPrice(newValue) {
      //先判断是否选中商品优惠，如果没有则报错且退回
      let findItem = this.discountInfoList.find((item) => item.isSelected);
      if (!findItem) {
        this.$message.error("请先选择商品优惠");
        return;
      }

      let value = Number(newValue);
      if (isNaN(value) || newValue.startsWith("00")) {
        this.$message.error("请输入大于0的优惠金额");
        setTimeout(() => {
          this.discountPrice = "";
          this.getReceiveItem();
        });
        return;
      }

      newValue = value;
      if (newValue < 0) {
        this.$message.error("请输入大于0的优惠金额");
        setTimeout(() => {
          this.discountPrice = "";
          this.getReceiveItem();
        });
        return;
      }

      if (newValue > Number(this.newGoodsPrice)) {
        this.$message.error(`请输入不大商品价格 ${this.newGoodsPrice}`);
        setTimeout(() => {
          this.discountPrice = "";
          this.getReceiveItem();
        });
        return;
      }

      let discount = Number(findItem.preferentialAmount);
      this.discountNewPrice = discount
      console.log('discount', discount);
      // if (newValue > discount) {
      //   this.$message.error(`请输入不大于最大优惠金额${discount}`);
      //   setTimeout(() => {
      //     this.discountPrice = "";
      //     this.getReceiveItem();
      //   });
      //   return;
      // }
      this.getReceiveItem();
    },


    /***
     * 选择优惠券信息
     */
    selectDiscountInfo(id) {
      if(!id){
        this.wxTradeOrderDiscountVOList = [];
        this.discountPrice = "";
        this.discountType="";
         this.getReceiveItem();
        return;
      }
      this.discountInfoList.map(item => {
        if(item.id == id) {
          this.marketInoutConfigSnapshotId = item.marketInoutConfigSnapshotId
          this.inoutConfigSnapshot = item.inoutConfigSnapshot
        }
      })
      this.discountInfoList.forEach((item) => (item.isSelected = false));
      let findItem = this.discountInfoList.find((item) => item.id == id);
      findItem.isSelected = true;
      this.wxTradeOrderDiscountVOList = [
        {
          activityId: findItem.activityId,
          discount: 0,
          // 优惠新增4个字段
          ...this.activedParams,
          marketInoutConfigSnapshotId: this.marketInoutConfigSnapshotId 
        },
      ];
      this.discountTip = findItem.endTime
        ? `可优惠0~${findItem.preferentialAmount}元 ${this.$dayjs(
            findItem.endTime
          ).format("YYYY-MM-DD HH:mm:ss")} 过期`
        : `可优惠0~${findItem.preferentialAmount}元 本优惠长期有效`;
      this.getReceiveItem();
    },

    // 收费项目合计
    receiveItemSummaryMethod(param) {
      const { columns = [], data = [] } = param;
      const sums = [];

      if (!columns.length || !data.length) {
        return sums;
      }
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }

        //表示只计算最后一项。
        if (index < columns.length - 1) return "";
        const values = data.map((item) => Number(item.receivable));
        let totalPrice = values.reduce((prev, curr) => {
          return prev + (Number(curr) || 0);
        });
        sums[index] = totalPrice.toFixed(2);
      });
      return sums;
    },
    // 获取院校选择的schoolNcId
    async selectSchoolNcid(value,valueid,name) {
      console.log('value获取院校选择的schoolNcId', value);
      // const { code, result } = await getDealSummary(this.customerId)
      let gradationId
      let goodsId
      let id
      this.ckSingle.specificationPriceDataSource.map((item) => {
        goodsId = item.goodsId
        id = item.id
        if(item.enrollingMajorList && item.enrollingMajorList.length) {
          gradationId = item.enrollingMajorList[0].gradationId
        }
      })
      this.currentGoods.enrollingSchoolName = name
      this.currentGoods.enrollingSchoolId = valueid
      console.log('gradationId-----=====',gradationId);
      console.log('this.currentGoods', this.currentGoods);
      // if (!gradationId || gradationId == 0) {
      //   this.$message.error('')
      // }
      await this.$fetch("third_getfindEnrollingMajorList", {schoolNcId: value, gradationId: gradationId, id: id, goodsId: goodsId}).then(res => {
        if(res.code === 200) {
          res.data.map((item) => {
            if (item.name) {
              item.majorName = item.name
            }
          })
          // this.ckSingle.enrollingMajorList = res.data
          // this.ckSingle.specificationPriceDataSource.map(item => {
          //   if(item.enrollingMajorList && item.enrollingMajorList.length) {
          //     item.enrollingMajorList.map(i => {
          //       res.data.map(j => {
          //         if(j.majorId === i.majorId) {
          //           j.gradationId = i.gradationId
          //         }
          //       })
          //     })
          //   }
          // })
          this.ckSingle.enrollingMajorList = res.data;
          // console.log('this.ckSingle.enrollingMajorList', this.ckSingle.enrollingMajorList);
          this.majorList = res.data
        }
      })
    },
    // 获取院校
    async getfindEnrollingSchoolList () {
      const { data } = await this.$fetch("third_getfindEnrollingSchoolList");
      console.log('data院校', data);
      data.map((item) => {
        if (item.children) {
          item.disabled = true
          item.children.map((i) => {
            if(!i.schoolNcId) {
              i.disabled = true
            }
          })
        }
      })
      this.findSchoolList = data
    },

    // 第二志愿
    async selectSchoolNcidtwo(value,valueid,name) {
      console.log('value获取院校选择的schoolNcId2', value);
      // const { code, result } = await getDealSummary(this.customerId)
      let gradationId
      let goodsId
      let id
      this.ckSingle.specificationPriceDataSource.map((item) => {
        goodsId = item.goodsId
        id = item.id
        if(item.enrollingMajorList && item.enrollingMajorList.length) {
          gradationId = item.enrollingMajorList[0].gradationId
        }
      })
      this.currentGoods_second.enrollingSchoolName = name
      this.currentGoods_second.enrollingSchoolId = valueid
      console.log('gradationId第二-=-=-=', gradationId);
      console.log('this.currentGoods', this.currentGoods);
      await this.$fetch("third_getfindEnrollingMajorList", {schoolNcId: value, gradationId: gradationId, id: id, goodsId: goodsId}).then(res => {
        if(res.code === 200) {
          res.data.map((item) => {
            if (item.name) {
              item.majorName = item.name
            }
          })
          this.ckSingle.enrollingMajorList_second = res.data;
        }
      })
    },
    // 获取院校2
    async getfindEnrollingSchoolListwo () {
      const { data } = await this.$fetch("third_getfindEnrollingSchoolList");
      console.log('data院校', data);
      data.map((item) => {
        if (item.children) {
          item.disabled = true
          item.children.map((i) => {
            if(!i.schoolNcId) {
              i.disabled = true
            }
          })
        }
      })
      this.findtwoSchoolList = data
    },

    /********************成考相关数据请求******************************************************************** */
    async getCkGoodsSpecs() {
      let form = {
        goodsId: this.currentGoods.goodsId,
        businessId: 0,
      };
      const { data } = await this.$fetch("third_getfindSpByIdGoodsId", form);
      // 通过canSelectMajor来判断是不是可选择的
      console.log('data.canSelectMajor', data.canSelectMajor);
      console.log('data.canSelectMajor', data.canSelectMajor == true);
      if (data.canSelectMajor && data.canSelectMajor == true) {
        this.canSelectMajor = true
        this.getfindEnrollingSchoolList()
          this.getfindEnrollingSchoolListwo()
          this.showYxOption = true
          this.showYxOption2 = true
          this.disabledsp = false
        } else {
          this.disabledsp = true
          // 如果没有就走之前的逻辑
          this.canSelectMajor = false
          this.showYxOption = false
          this.showYxOption2 = false
          if (this.xlType == 2 && data.canSelectMajor == true) {
            this.showYxOption = true
          }
        }
        console.log('disabledsp',this.disabledsp);
        console.log('showYxOption2',this.showYxOption2,this.showYxOption,this.xlType);
      this.ckSingle.enrollingGradationDatasource =
        data.enrollingGradationList || [];
      this.ckSingle.enrollingMajorList = data.enrollingMajorList || [];
      this.ckSingle.specificationPriceDataSource =
        data.specificationPriceArr || [];
      this.specificationPriceArr = data.specificationPriceArr || [];
      this.ckSingle.goodsId = data.goodsId;
      this.ckSingle.xlType = data.xlType;
      this.getCkSingleCourseInfoTableData(this.ckSingle.goodsId);
      // this.startActivityDetail([]);
    },

    //获取课程信息
    //goodsIdStr 商品id字符串（用’,’隔开）
    async getCkSingleCourseInfoTableData(goodsIdStr) {
      let form = {
        goodsIdStr,
      };
      const { data = [] } = await this.$fetch(
        "third_findCourseByGoodsIdStr",
        form
      );
      let coursrDatasource = data[0] || {};
      this.ckSingle.courseList = coursrDatasource.goodsCourseList || [];
    },

    selectCkSingleProfessional_second(majorId) {
      console.log('majorId', majorId);
      let index = 0;
      let curEnrollingMajorItem = undefined;
      console.log('this.ckSingle.enrollingMajorList_second----', this.ckSingle.enrollingMajorList_second);

      let enrollingMajorList_second = this.ckSingle.enrollingMajorList_second
      if (this.showYxOption == true) { 
         for( let i in enrollingMajorList_second){
          let item = enrollingMajorList_second[i];
          if( item.majorId === majorId ){
            console.log('i第一层循环', i);
            index = 0;
            curEnrollingMajorItem = item;
            break;
          }
        }
      }else {
        for (let i in this.ckSingle.enrollingMajorList_second) {
          let item = this.ckSingle.enrollingMajorList_second[i];
          if (item.id === majorId) {
            console.log('i第二层循环', i);
            index = i;
            curEnrollingMajorItem = item;
            break;
          }
        }
      }
      console.log('curEnrollingMajorItem=====', curEnrollingMajorItem);

      let firstMajorId = this.graduactionForm.enrollingMajorIdList[0];
      if (firstMajorId && curEnrollingMajorItem.majorId == firstMajorId) {
        this.$message.error("第二志愿不能选择相同的专业");
        setTimeout(() => {
          this.ckSingle.form.enrollingMajorId_second = "";
        }, 0);
        return;
      }

      this.getCkSingleGoodsSpecTable_second(curEnrollingMajorItem.majorId);

      //报考专业 majorId
      this.graduactionForm.enrollingMajorIdList_second = [
        curEnrollingMajorItem.majorId,
      ];

      //报考专业 majorName
      this.graduactionForm.enrollingMajorNameList_second = [
        curEnrollingMajorItem.majorName,
      ];
      //报考院校 schoolId,
      this.graduactionForm.enrollingSchoolIdList_second = [
        this.currentGoods_second.enrollingSchoolId,
      ];
      //报考院校 schoolName
      this.graduactionForm.enrollingSchoolNameList_second = [
        this.currentGoods_second.enrollingSchoolName,
      ];
       console.log('index',index);
       let specificationPriceItem = this.ckSingle.specificationPriceDataSource_second[index];
      //  if (index == 1 || index == 2) {
      //    specificationPriceItem =
      //   this.ckSingle.specificationPriceDataSource_second[0];
      //  } else {
      //    //根据选择的报考专业来获取对应的 specificationPriceArr 中的 专业层次
      //     specificationPriceItem =
      //      this.ckSingle.specificationPriceDataSource_second[index];
      //  }
      this.graduactionForm.gradationIdList_second = [
        specificationPriceItem.enrollingMajorList[index].gradationId,
      ];
      console.log('specificationPriceItem', specificationPriceItem);
      console.log('this.graduactionForm.gradationIdList_second', this.graduactionForm.gradationIdList_second);
    },

    async getCkGoodsSpecs_second() {
      let form = {
        goodsId: this.currentGoods_second.goodsId,
        businessId: this.currentGoods_second.businessId,
      };
      const { data } = await this.$fetch("third_getfindSpByIdGoodsId", form);
      if (data.canSelectMajor && data.canSelectMajor == true) {
          this.getfindEnrollingSchoolListwo()
          this.showYxOption2 = true
        } else {
          // 如果没有就走之前的逻辑
          this.showYxOption2 = false
        }

      this.ckSingle.enrollingGradationDatasource_second =
        data.enrollingGradationList || [];
      this.ckSingle.enrollingMajorList_second = data.enrollingMajorList || [];

      //过滤掉不是相同专业的数据
      let firstGoodsGraduactionId = this.graduactionForm.gradationIdList[0];
      let specificationPriceArr = data.specificationPriceArr || [];
      specificationPriceArr = specificationPriceArr.filter((item) => {
        let enrollingMajorList = item.enrollingMajorList || [];
        return !!enrollingMajorList.find(
          (findItem) => findItem.gradationId == firstGoodsGraduactionId
        );
      });
      this.ckSingle.specificationPriceDataSource_second = specificationPriceArr;

      this.ckSingle.goodsId_second = data.goodsId;
      this.ckSingle.xlType_second = data.xlType;
    },

    selectCkSingleProfessional(majorId) {
      let index = 0;
      let curEnrollingMajorItem = undefined;
      let enrollingMajorList = this.ckSingle.enrollingMajorList
      console.log('this.ckSingle.enrollingMajorList第一级', this.ckSingle.enrollingMajorList);
      if (this.showYxOption == true) {
        for( let i in  enrollingMajorList){
          let item = enrollingMajorList[i];
          if( item.majorId === majorId ){
            console.log('i第一级',i);
            index = 0;
            curEnrollingMajorItem = item;
            break;
          }
        }
      } else {
        for (let i in this.ckSingle.enrollingMajorList) {
          let item = this.ckSingle.enrollingMajorList[i];
          if (item.id === majorId) {
            console.log('i第二级',i);
            index = i;
            curEnrollingMajorItem = item;
            break;
          }
        }
      }

      this.ckSingle.enrollingGradationList =
        this.ckSingle.enrollingGradationDatasource || [];
      this.ckSingle.form.enrollingGradationId = "";
      console.log('curEnrollingMajorItem=====1', curEnrollingMajorItem);
      this.getCkSingleGoodsSpecTable(curEnrollingMajorItem.majorId);
      // this.getfindPlatformBySpPriceId();
      //报考专业 majorId, majorName
      this.graduactionForm.enrollingMajorIdList = [
        curEnrollingMajorItem.majorId,
      ];

      this.graduactionForm.enrollingMajorNameList = [
        curEnrollingMajorItem.majorName,
      ];
      //报考院校 schoolId,   schoolName
      this.graduactionForm.enrollingSchoolIdList = [
        this.currentGoods.enrollingSchoolId,
      ];
      this.graduactionForm.enrollingSchoolNameList = [
        this.currentGoods.enrollingSchoolName,
      ];
      //报考省份 provinceId, provinceName
      this.graduactionForm.enrollingProvinceIdList = [
        this.currentGoods.enrollingProvinceId,
      ];
      this.graduactionForm.enrollingProvinceNameList = [""];
      console.log('index',index);
      //根据选择的报考专业来获取对应的 specificationPriceArr 中的 专业层次
      let specificationPriceItem = this.ckSingle.specificationPriceDataSource[index];
      // if (index == 1 || index == 2) {
      //   specificationPriceItem =
      //   this.ckSingle.specificationPriceDataSource[0];
      // }else {
      //   specificationPriceItem =
      //   this.ckSingle.specificationPriceDataSource[index];
      // }
      console.log('specificationPriceItem', specificationPriceItem);
      this.graduactionForm.gradationIdList = [
        specificationPriceItem.enrollingMajorList[0].gradationId,
      ];
      console.log('this.graduactionForm.gradationIdList选择', this.graduactionForm.gradationIdList);
    },

    selectCkSingleGradation(gradationId) {
      let enrollingGradationItem = this.ckSingle.enrollingGradationList.find(
        (item) => item.id === gradationId
      );
      console.log('enrollingGradationItem', enrollingGradationItem);
      this.ckSingle.enrollingTime = enrollingGradationItem.gradation;
      this.ckSingle.gradationYear = enrollingGradationItem.gradationYear;

      // 判断成考网教是否复购
      this.getOrderStatus()
    },

    //班型table
    getCkSingleGoodsSpecTable(majorId) {
      let curClassInfo
      if (this.showYxOption == true) {
        curClassInfo = this.ckSingle.specificationPriceDataSource[0]
      } else {
        curClassInfo =
          this.ckSingle.specificationPriceDataSource.find(
            (item) => item.majorId == majorId
          ) || {};
      }
      console.log('选择后',curClassInfo);
      this.ckSingle.goodsPrice = curClassInfo.goodsPrice;
      this.zkNewGoodsPrice = curClassInfo.goodsPrice; //实际支付价格
      this.ckSingle.effectiveMonth = curClassInfo.effectiveMonth;
      this.goodsSpecTable = [curClassInfo];
      this.specIdArr = [curClassInfo.id];
      this.commodityIdList = [curClassInfo.id];
      this.getReceiveItem();
      this.startActivityDetail([])
      this.totalPrice = curClassInfo.goodsPrice;
      console.log('this.commodityIdList选择班型', this.commodityIdList)
    },

    //班型table _second
    getCkSingleGoodsSpecTable_second(majorId) {
      let curClassInfo =
        this.ckSingle.specificationPriceDataSource_second.find(
          (item) => item.majorId == majorId
        ) || {};

      this.commodityIdList_second = [curClassInfo.id];
    },

    /********************************************************************************************* */

    /**判断是否是手机号**/
    isPhoneNumber(tel) {
      var reg = /^1\d{10}$/;
      return reg.test(tel);
    },

    /**
     * 获得套餐所有已选中的课程
     * */
    getAllSeledClass() {
      if (
        this.$refs.selcetionClass &&
        this.$refs.selcetionClass.lessonStatus &&
        this.currentGoods.type === 1
      ) {
        this.$message.error("请选保存选择");
        return false;
      }

      this.userSelectMealGroup = this.mealGroupData.reduce((prev, last) => {
        return [...prev, ...last.goods.filter((subItem) => subItem.checked)];
      }, []);
      return true;
    },

    // 根据手机号获取学员的账户余额
    async getBalanceByMoblie(mobile) {
      if (!mobile) {
        return this.$message.error("请输入手机账号");
      }
      const params = { mobile };
      const { data, code, msg } = await this.$fetch(
        "thrid_getBalanceByMoblie",
        params
      );
      if (code === 407) {
        return this.$message.error(msg);
      }
      if (!data) return false;

      this.collectionInfo.stuAccountMoney = data.money;
      this.collectionInfo.userName = data.nickName;
    },

    onSureCancel() {
      this.sureVisible = false;
    },

    /**
     *  //(1) 比较财经商品的价格是否变动
     *  //(2) 比较成考、网教的价格是否变动
     * */
    comparePrice(val = []) {
      return this.specificationPriceArr.some((i) => {
        return val.some((j) => {
          return (
            j.id == i.id &&
            (j.goodsPrice != i.goodsPrice ||
              j.goodsDiscountPrice != i.goodsDiscountPrice)
          );
        });
      });
    },

    validateCondition() {
      //成考、网教
      if (this.ctr.showCkSingleOption) {
        if (!this.ckSingle.form.enrollingMajorId) {
          this.$message({ type: "error", message: "请选择专业" });
          return false;
        }

        if (!this.ckSingle.form.enrollingGradationId) {
          this.$message({ type: "error", message: "请选择学级" });
          return false;
        }

        //如果是成考，需要判断第二志愿
        if (this.currentGoods.xlType == 2) {
          //第二志愿的商品
          // if (!Object.keys(this.currentGoods_second).length) {
          //   this.$message({ type: "error", message: "请选择第二志愿商品" });
          //   return false;
          // }
          //第二志愿的专业
          if (Object.keys(this.currentGoods_second).length && !this.ckSingle.form.enrollingMajorId_second) {
            this.$message({ type: "error", message: "请选择第二志愿专业" });
            return false;
          }
          //第一志愿不能与第二志愿专业相同
           if( this.ckSingle.form.enrollingMajorId == this.ckSingle.form.enrollingMajorId_second && this.ckSingle.form.enrollingMajorId && this.ckSingle.form.enrollingMajorId_second){
             this.$message({ type: "error", message: "第一志愿专业不能与第二志愿专业相同" });
             return false;
           }
        }
      }
      return true;
    },

    /***
     * 判断商品价格是否变化
     */
    async getGoodsDetail(specIdArr) {
      let spPriceIdStr = specIdArr.toString();
      if (spPriceIdStr === "") return;
      const { data } = await this.$fetch("findPriceInfoBySpPriceId", {
        spPriceIdStr,
      });
      //价格没有发生变化
      if (!this.comparePrice(data)) {
        return true;
      }

      this.$hqMessageBox({
        title: "提示",
        message: "此商品价格发生变化，请重新选择该商品购买！",
      })
        .then(() => {
          //点击商品重置按钮
          this.removeChoiceGoods();
        })
        .catch(() => {
          //点击商品重置按钮
          this.removeChoiceGoods();
        });
      this.submitLoading = false;
      this.sureVisible = false;
      return false;
    },

    // 提交函数---放在获取贷款信息中
    async getSureData(form) {
      //流量经纪人存在  或者流量合伙人存在, 则必须选择促单形式
      if (
        (this.transactionInfo.economicFlowPartner) &&
        !this.transactionInfo.promotionForm
      ) {
        this.$message.error("存在经纪人的情形下，必须选择促单形式！");
        return;
      }

      // if(this.transactionInfo.channelsTypeName == '财经流量合伙人' && !this.transactionInfo.promotionForm) {
      //   this.$message.error("招生途径为 '财经流量合伙人' ，必须选择促单形式！");
      //   return;
      // } else if(this.transactionInfo.channelsTypeName != '财经流量合伙人') {
      //   this.transactionInfo.promotionForm = ""
      // }

      this.$refs[form].validate(async (valid) => {
        if (!valid || this.repeat) return this.$message.error("表单填写错误，请检查！");
        if(!this.statusPrice) return this.$message.error('商品收支项目未配置，暂不支持报名，请联系商品管理老师')
        if (!this.validateCondition()) {
          return false;
        }
        //(1) 查看是否购买过该课程
        // let res = await this.findOrderStatus();
        // if (res) {
        //   return false;
        // }

        //(2) 查看选中的课程是否保存
        let res = await this.getAllSeledClass();
        if (!res) {
          return false;
        }

        let obj = {
          //商品信息
          goodsName: this.signUpForm.goodsName,
          //规格信息
          spec: this.signUpForm.spec,
          //学员余额抵扣号码
          mobile: this.collectionInfo.stuMoblie,
          //学员账号
          stuMobile: this.signUpForm.stuMoblie,
          //学员姓名
          name: this.signUpForm.name,
          //身份证
          idCard: this.signUpForm.idCard,
          //商品价格
          goodsPrice: this.newGoodsPrice,
          //应收金额
          finalPrice: this.shouldPay,
          // 应收金额
          allGoodsPrice: this.shouldPay,
          //优惠金额
          goodsDiscount: this.discountPrice,
          type: "sign-up",
        };

        //成考、网教, 需要增加额外的数据
        if (this.ctr.showCkSingleOption) {
          obj.enrollingSchoolName = this.currentGoods.enrollingSchoolName;
          //报考专业
          obj.enrollingMajorId = this.graduactionForm.enrollingMajorNameList[0];
          //报考学级
          obj.enrollingGradationId = this.ckSingle.gradationYear;
        }

        //规格名称
        obj.goodsDetail = this.signUpForm.spec;
        console.log('this.sureData', this.sureData);
        this.sureData = obj;

        //顺着之前天翼的思路，将数据传递采用了localstorage的方式
        localStorage.setItem("sureData", JSON.stringify(this.sureData));
        localStorage.setItem(
          "showCkSingleOption",
          JSON.stringify(this.ctr.showCkSingleOption)
        );
        localStorage.setItem(
          "showCjSingleOption",
          JSON.stringify(this.ctr.showCjSingleOption)
        );

        this.sureVisible = true;
      });
    },

    async onSureOk() {
      this.submitLoading = true;

      //报考省份中的规格id specIdArr
      let enrollingProvinceItemIdList =
        this.graduactionForm.enrollingProvinceItemIdList || [];
      let specIdArr = [...this.specIdArr, ...enrollingProvinceItemIdList];

      //判断商品价格是否发生变化
      let isNotChange = await this.getGoodsDetail(specIdArr);
      if (!isNotChange) {
        return;
      }

      //发起订单提交

      this.onSubmit("signUpForm");
    },
    getcheckZeroOrder(val) {
      this.$fetch("thrid_checkZeroOrder", params).then(res => {

      }).catch(res => {
        if (res.code == 500) {
          return this.$message.error(res.msg)
        }
      })
    },
    /***
     * 提交按钮点击之后展示确认页面
     */
    async showSureData() {
      this.getthrid_checkCost() 
    },

    /**
     * 重置按钮清空信息
     * */
    removeChoiceGoods() {
      //商品名称
      this.signUpForm.goodsName = "";
      this.removeDiscountInfo();
      //重置商品选择信息
      this.resetGoodSelectedInfo();

      this.$refs.deptsys && this.$refs.deptsys.init()
      this.$refs.deptsystwo && this.$refs.deptsystwo.init()
      this.showYxOption = false
      this.showYxOption2 = false

      this.graduactionForm = {
        enrollingProvinceIdList: [], //报考省份名称集合
        enrollingProvinceNameList: [], //报考省份名称集合
        enrollingMajorIdList: [], //报考专业id集合
        enrollingMajorNameList: [], //报考专业名称集合
        enrollingSchoolIdList: [], //报考院校id集合
        enrollingSchoolNameList: [], //报考院校名称集合
        gradationIdList: [], //报考层次(1专科  2本科)
        enrollingTime: "", //报考时间(用于记录成考、网教报考学级)

        enrollingMajorIdList_second: [], //报考专业id集合
        enrollingMajorNameList_second: [], //报考专业名称集合
        enrollingSchoolIdList_second: [], //报考院校id集合
        enrollingSchoolNameList_second: [], //报考省份名称集合
        gradationIdList_second: [], //报考层次(1专科  2本科)
      }
    },

    resetGoodSelectedInfo() {
      this.ckSingle.form.enrollingMajorId = "";
      this.ckSingle.form.enrollingGradationId = "";
      this.ckSingle.effectiveMonth = "";
      this.ckSingle.goodsPrice = "";

      this.signUpForm.spec = "";
      this.goodsSpecTable = [];
      this.mealGroupData = [];
      this.specIdArr = [];
      this.totalPrice = 0;
      this.oneItem = [];
      this.currentGoods = {};
      this.currentGoods_second = {};
      this.receiveItemData = [];
      this.removeZkChoiceSpc();
      this.ckSingle.effectiveMonth = "";
      this.ctr.showCjSingleOption = false;
      this.ctr.showCkSingleOption = false;
    },

    removeZkChoiceSpc() {
      this.resetAfterPrefessionalData();
    },

    removeChoiceSpc() {
      this.signUpForm.spec = "";
      this.goodsSpecTable = [];
      this.mealGroupData = [];
      this.specIdArr = [];
      this.totalPrice = 0;
      // this.resetBalancePay();
    },

    removeDiscountInfo() {
      this.discountType = "";
      this.discountInfoList = [];
      this.discountNotuse = []
      this.wxTradeOrderDiscountVOList = [];
      this.discountTip = "";
      this.discountPrice = "";
      this.signUpForm.jobLowestSalaryUnderCollege = ''
      this.signUpForm.jobLowestSalaryUpCollege = ''
    },

    resetAfterPrefessionalData() {
      this.currentGoods_second = {};

      this.ckSingle.goodsId = "";
      this.ckSingle.goodsPrice = 0;
      this.ckSingle.enrollingGradationDatasource = [];
      this.ckSingle.enrollingGradationList = [];
      this.ckSingle.enrollingMajorList = [];
      this.ckSingle.specificationPriceDataSource = [];
      this.ckSingle.courseList = [];
      this.ckSingle.form.enrollingGradationId = "";
      this.ckSingle.form.enrollingMajorId = "";

      this.ckSingle.goodsId_second = "";
      this.ckSingle.enrollingGradationDatasource_second = [];
      this.ckSingle.enrollingGradationList_second = [];
      this.ckSingle.enrollingMajorList_second = [];
      this.ckSingle.specificationPriceDataSource_second = [];
      this.ckSingle.courseList_second = [];
      this.ckSingle.form.enrollingGradationId_second = "";
      this.ckSingle.form.enrollingMajorId_second = "";

      this.zkNewGoodsPrice = 0;
      this.zkGoodsDiscount = 0;
    },

    // 选择商品规格回调
    onChoiceGoodsSpec() {
      if (!this.currentGoods) {
        return this.$message.error("请先选择商品");
      }

      //对于默认规格，默认班型, 默认，都不响应选择事件
      if (
        ["默认规格", "默认班型", "默认"].indexOf(`${this.signUpForm.spec}`) > -1
      ) {
        return;
      }

      this.specIds = [];

      let filterSpecPriceArr = this.specPriceArr.filter(
        (item) => item.status != 1
      );
      for (let specTypeArrIndex in this.specTypeArr) {
        let specTypeArrItem = this.specTypeArr[specTypeArrIndex] || {};
        let specificationNameArr = specTypeArrItem.specificationNameArr || [];
        for (let specificationNameItem of specificationNameArr) {
          let result = filterSpecPriceArr.find(
            (findItem) =>
              findItem.specificationNameId1 == specificationNameItem.id
          );
          // specificationNameItem.isStop = !!result;
        }
      }

      localStorage.setItem(
        "specTypeArr",
        JSON.stringify(this.specTypeArr || [])
      );
      localStorage.setItem(
        "specPriceArr",
        JSON.stringify(this.specPriceArr || [])
      );
      localStorage.setItem("specArray", JSON.stringify(this.specArray || []));
      localStorage.setItem(
        "currentGoodsRank",
        JSON.stringify(this.currentGoodsRank)
      );
      localStorage.setItem(
        "specCountArr",
        JSON.stringify(this.specCountArr || [])
      );

      this.gsVisible = true;
    },

    // 选择商品对应回调
    onChoiceGoods() {
      this.goodsForm = {};
      this.goodsDrawerVisible = true;
    },

    /**
     * 第二志愿的商品选择
     */
    onChoiceGoods_second() {
      this.goodsForm.goodsId = ""
      if (
        !this.graduactionForm.enrollingMajorIdList ||
        this.graduactionForm.enrollingMajorIdList.length == 0 ||
        !this.ckSingle.enrollingTime
      ) {
        this.$message.error("请先选择第一志愿商品、报考专业、报考学级");
        return;
      }
      this.goodsForm_second = {};
      if (this.goodsDrawerVisible_second) {
        this.goodsDrawerVisible_second = false;
        this.$nextTick(() => {
          this.goodsDrawerVisible_second = true;
        });
      }
      this.goodsDrawerVisible_second = true;
    },

    // 关闭商品规格弹窗
    onGsCancel() {
      this.gsVisible = false;
    },
    // 选择班型时候触发
    async getthrid_checkCost() {
      let params = {
        goodsId: this.currentGoods.goodsId, //商品id
        businessIdInt: this.currentGoods.businessId,
        commodityIdList: this.isCj ? this.specIdArr : [...this.commodityIdList], //, //商品规格价格id集合
      }
      this.$fetch("thrid_checkCost", params).then(res => {
        this.getSureData("signUpForm");
      }).catch(res => {
        if (res.code == 500) {
          return this.$message.error(res.msg)
        }
      })
    },

    /**
     * 选择商品规格弹窗确定回调
     * */
    onSpecModalOk(specInfo, specificationPriceArr) {
      this.removeDiscountInfo();
      this.gsVisible = false;
      
      const { totalPrice, specIdArr, specificationName, specArr } = specInfo;
      // this.startActivityDetail(specArr);
      /**
       * 处理财经
       */
      this.totalPrice = totalPrice;
      this.specIdArr = specIdArr;
      //班型名称
      this.signUpForm.spec = specificationName;
      this.goodsSpecTable = specificationPriceArr
        ? specificationPriceArr
        : this.getSpcMergeData(specArr);
      //判断是否购买过
      this.getOrderStatus();
      //收费标准的数据。
      this.getReceiveItem();

      /***
       * 财经单品信息： type:0
       */
      if (this.currentGoods.type === 0) {
        this.findGoodsCourseBySpPriceId();
      }
      //普通套餐: type:1
      if (this.currentGoods.type == 1) {
        this.getSetMealGroupInfo();
      }
      //组合套餐： type:1 且 goodsTemplate:6
      if (this.currentGoods.type && this.currentGoods.goodsTemplate == 6) {
        this.findComComGoodsInfo();
      }

      this.startActivityDetail(specArr);
      this.getEmploymentContract()
    },

    // 用于判断选择班型后是否已经购买过了
    async getOrderStatus() {
      if (!this.signUpForm.userId) {
        return false;
      }
      if (this.canSelectMajor == true) {
        const { data = {} } = await this.$fetch(
          "findOrderStatusByUserIdAndSpecificationId",
          {
            userId: this.signUpForm.userId,
            spPriceIdStr: this.specIdArr.toString(),
            enrollingTime: this.isCj?'':this.ckSingle.gradationYear.substring(0, this.ckSingle.gradationYear.length - 2),
            enrollingSchoolId: this.currentGoods.enrollingSchoolId,
            enrollingMajorId: this.ckSingle.form.enrollingMajorId,
            canSelectMajor: true
          }
        );
  
        if(data.purchaseStatusResultStatus == 8) {
          this.ckMessage = "学员已报名【成考/网教】【学级】班型，请勿重复报名"
          this.repeat = true
          // this.$message.error("学员已报名该班型，请重新选择班型");
          return true
        } else {
          this.repeat = false
        }
  
        if(data.purchaseStatusResultStatus != 0 && data.purchaseStatusResultStatus != 8) {
          this.ckMessage = "学员已报名该班型，请勿重复报名"
          this.repeat = true
          // this.$message.error("学员已报名该班型，请重新选择班型");
          return true
        } else {
          this.repeat = false
        }
      } else {
        const { data = {} } = await this.$fetch(
          "findOrderStatusByUserIdAndSpecificationId",
          {
            userId: this.signUpForm.userId,
            spPriceIdStr: this.specIdArr.toString(),
            enrollingTime: this.isCj?'':this.ckSingle.gradationYear.substring(0, this.ckSingle.gradationYear.length - 2),
          }
        );
  
        if(data.purchaseStatusResultStatus == 8) {
          this.ckMessage = "学员已报名【成考/网教】【学级】班型，请勿重复报名"
          this.repeat = true
          // this.$message.error("学员已报名该班型，请重新选择班型");
          return true
        } else {
          this.repeat = false
        }
  
        if(data.purchaseStatusResultStatus != 0 && data.purchaseStatusResultStatus != 8) {
          this.ckMessage = "学员已报名该班型，请勿重复报名"
          this.repeat = true
          // this.$message.error("学员已报名该班型，请重新选择班型");
          return true
        } else {
          this.repeat = false
        }
      }

      // let result = data.purchaseStatusResultStatus !== 0;
      // result && this.$message.error("学员已报名该班型，请重新选择班型");
      // return result;
    },

    //获取非组合套餐的收支项
    async getReceiveItem() {
      // 选择的套餐课程
      let userSelectMealGroupIds =
        this.userSelectMealGroup.map(
          (item) => item.goodsSpecificationPriceId
        ) || [];

      const res = await this.$fetch("queryBySpStrAndGoodsTemp", {
        spStr: this.specIdArr.toString(),
        goodsTemp: this.currentGoods.goodsTemplate,
        upClassDecimal: 0,
        goodsDiscounts: this.discountPrice,
        childSpecificationPriceIds: userSelectMealGroupIds.toString(),
        inoutConfigSnapshot: this.inoutConfigSnapshot,
        newGoodsPrice: this.newGoodsPrice,
        shouldPay: this.shouldPay
      });

      this.receiveItemData = res.data.inoutProjectList;
      if(res.data && res.data.moreThanFlag) { // 3578
        if (this.discountNewPrice < res.data.maxDiscountAmount) {
          this.$message.error(`请输入不大于最大优惠金额${ this.discountNewPrice }`);
        } else {
          this.$message.error(`请输入不大于收支项目优惠金额${res.data.maxDiscountAmount}，如有问题请联系管理员`)
        }
        this.discountPrice = ""
        this.getReceiveItem()
      } else if(this.discountPrice > this.discountNewPrice){
          this.$message.error(`请输入不大于最大优惠金额${ this.discountNewPrice }`);
          this.discountPrice = ""
      }
    },


    getSpcMergeData(data) {
      let obj = {
        kuaidaTotal: 0,
        kuaidaFrequency: 0,
      };
      let goodsPrice = 0; 
      let goodsDiscountPrice = 0;
      data.forEach((item) => {
        if (item.goodsPrice) {
          goodsPrice += (Number(item.goodsPrice) || 0);
        }
        if (item.goodsDiscountPrice) {
          goodsDiscountPrice += (Number(item.goodsDiscountPrice) || 0);
        }
        if (`${item.kuaidaTotal}` === "0") {
          item.kuaidaTotal = "无上限";
        }
        if (item.kuaidaTotal == null) {
          item.kuaidaTotal = "--";
        }
        if (["默认", "默认规格", "默认班型"].indexOf(item.spPriceName) == -1) {
          item.goodsName = `${item.goodsName}-${item.spPriceName}`;
        }
      });
      obj = Object.assign(obj, data[0]);
      obj.goodsPrice = accAdd(0, goodsPrice, 2);
      obj.goodsDiscountPrice = data[0].goodsDiscountPrice
        ? accAdd(0, goodsDiscountPrice, 2)
        : "--";
      return [obj];
    },

    /***
     * 获取组合套餐信息
     * 参数: spPriceIdStr
     */
    async findComComGoodsInfo() {
      // alert("findComComGoodsInfo")
      const params = {
        spPriceIdStr: this.specIdArr.toString(),
      };
      const { data } = await this.$fetch("third_findComComGoodsInfo", params);
      this.cjComComGoodsInfo = data || {};

      let singleGoodsSpPriceInfoList =
        this.cjComComGoodsInfo.singleGoodsSpPriceInfo || [];
      this.comComGoodsInfoList = [];
      for (let singleGoodsSpPriceInfoItem of singleGoodsSpPriceInfoList) {
        if (
          singleGoodsSpPriceInfoItem.kuaidaTotal === 0 ||
          singleGoodsSpPriceInfoItem.kuaidaTotal === "0"
        ) {
          singleGoodsSpPriceInfoItem.kuaidaTotal = "无上限";
        }
        if (singleGoodsSpPriceInfoItem.kuaidaTotal == null) {
          singleGoodsSpPriceInfoItem.kuaidaTotal = "--";
        }

        if (
          !(
            singleGoodsSpPriceInfoItem.spPriceName == "默认" ||
            singleGoodsSpPriceInfoItem.spPriceName == "默认班型" ||
            singleGoodsSpPriceInfoItem.spPriceName == "默认规格"
          )
        ) {
          singleGoodsSpPriceInfoItem.goodsName =
            singleGoodsSpPriceInfoItem.goodsName +
            "-" +
            singleGoodsSpPriceInfoItem.spPriceName;
        }
        this.comComGoodsInfoList.push({
          ...singleGoodsSpPriceInfoItem,
        });
      }
    },

    // 获取套餐分组信息
    async getSetMealGroupInfo() {
      const params = {
        goodsId: this.currentGoods.goodsId, //商品的id
        spPriceIdStr: this.specIdArr.toString(), //价格信息
      };

      const { data } = await this.$fetch("thrid_getGoodsGroupInfo", params);
      for (let i in data) {
        for (let j in data[i].goods) {
          data[i].closeStatus = true;
          data[i].goods[j].name = data[i].goods[j].name || "";
          data[i].goods[j].name = data[i].goods[j].name.replace(
            /(-默认$)|(-默认班型$)|(-默认规格$)/,
            ""
          );
          data[i].selComplete = false;
          if (data[i].sellStatus == 0) {
            data[i].goods[j].checked = true;
          } else {
            data[i].goods[j].checked = false;
          }
        }
      }
      this.mealGroupData = data;
      this.$nextTick(() => {
        this.mealGroupData.map((item) => {
          item.selected_count = 0;
          if (!item.sellStatus) {
            item.goods.map((obj) => {
              obj.disabled = true;
              obj.checked = true;
              if (this.$refs.multipleTable) {
                this.$refs.multipleTable.toggleRowSelection(obj);
              }
            });
          }
        });
      });
    },

    //获得单品的信息
    async findGoodsCourseBySpPriceId() {
      const params = {
        goodsId: this.currentGoods.goodsId,
        spPriceId: this.specIdArr.toString(),
      };
      const { data } = await this.$fetch("findGoodsCourseBySpPriceId", params);
      this.oneItem = data;
    },

    // 是否为存在就业协议
    async getEmploymentContract() {
      const res = await this.$fetch('employmentContract', {commodityIds: this.specIdArr.toString(), goodsId: this.currentGoods.goodsId})
      this.employmentShow = res.data
    },

    /**
     * 只接受成考的商品
     */
    async onGoodsDrawerOk_second(goods) {
      // if (this.currentGoods.goodsId === goods.goodsId) {
      //   this.$message.error("第二志愿的商品不能与第一志愿相同，请重新选择");
      //   return;
      // }
      // 定价状态 0 待定 1 已定
      this.statusPrice = goods.statusPrice
      //1、去除之前选择的商品信息，院校等信息
      this.goodsDrawerVisible_second = false;
      //2、填充商品名称
      this.currentGoods_second = goods;

      //表示是网考、成交，且是单品。
      this.ctr.showCkSingleOption = true;
      this.xlType = goods.xlType;
      //第二志愿报考专业
      this.ckSingle.form.enrollingMajorId_second = "";
      //第二志愿报考学级
      this.ckSingle.form.enrollingGradationId_second = "";
      this.getCkGoodsSpecs_second();
    },

    // signUpJudgeRecommend(goods) {
    //   addSignUpJudgeRecommend({
    //     sourceId: this.transactionInfo.sourceId,
    //     tyProductTypeId: goods.productId,
    //   })
    //     .then((res) => {
    //       let result = res.result || {};
    //       this.updateTransactionInfoStatus(
    //         result.isRecommand,
    //         result.isRecommand == 1 ? "是" : "否",
    //         result.teacherRecommandName,
    //         result.isRecommand == 1
    //           ? this.transactionInfo._ncTeacherRecommandUserId
    //           : ""
    //       );
    //     })
    //     .catch(() => {
    //       this.updateTransactionInfoStatus(0, "否", "", "");
    //     });
    // },

    // updateTransactionInfoStatus(
    //   isRecommand,
    //   isRecommandName,
    //   teacherRecommandName,
    //   ncTeacherRecommandUserId,
    // ) {
    //   Object.assign(this.transactionInfo, {
    //     isRecommand,
    //     isRecommandName,
    //     teacherRecommandName,
    //     ncTeacherRecommandUserId,
    //     transIntroduceType: isRecommand == 1?this.transactionInfo._transIntroduceType:''
    //   });
    // },

    // 选择商品确定
    /***
     *
     * 商品对线 curGoods 中的 type 字段判定商品类别（0：单品，1套餐）。
     * businessId	String	赛道id(学历：0) （必填）
     */
    onGoodsDrawerOk(goods) {
      this.removeChoiceGoods();
      //判断是否符合转介绍规则
      // this.signUpJudgeRecommend(goods);
      //存储选中的商品
      this.currentGoods = goods;
      this.currentGoodsRank = this.currentGoods.goodsRank;
      this.signUpForm.goodsName = goods.goodsName;

      // 定价状态 0 待定，1 已定
      this.statusPrice = goods.statusPrice

      //是不是财经
      this.isCj = `${goods.businessId}` === "1";
      //用于控制是否展示 财经模块 的ui
      this.ctr.showCjSingleOption = this.isCj;
      //用于控制是否展示 成考网教 的ui
      this.ctr.showCkSingleOption = !this.isCj;
      //商品中的 xlType 用于判断商品是成考还是网教。 2表示是成考，3表示网教。
      this.xlType =
        !this.isCj && (goods.xlType == 2 || goods.xlType == 3)
          ? goods.xlType
          : "";
      this.goodsDrawerVisible = false;
      //成考网教获取规格的对应方法不同。
      let getGoodsSpecs = this.isCj ? "getGoodsSpecs" : "getCkGoodsSpecs";
      this[getGoodsSpecs]();
    },

    // 获取财经商品规格接口
    async getGoodsSpecs() {
      // 重置商品规格弹窗数据
      this.signUpForm.spec = "";
      const params = {
        //businessId用于判断是 财经'1', 成考网教'0'
        businessId: this.currentGoods.businessId,
        //商品的id
        goodsId: this.currentGoods.goodsId,
      };

      //获取商品规格
      const { data } = await this.$fetch("getGoodsSpecs", params);
      this.specificationPriceArr = data.specificationPriceArr;
      let stopArr = []
      stopArr = data.specificationPriceArr.filter(item => item.status == 0)
      this.specPriceArr = data.specificationPriceArr;
      this.specCountArr = data.specificationSelectCountArr || [];
      this.specTypeArr = data.specificationTypeArr || [];

      //非默认班型下需要获取班型数据
      if (data.specificationTypeArr.length > 0) {
        this.getGoodsSpBySpType();
        this.getReceiveItem();
        return;
      }

      this.getOrderStatus();
      this.signUpForm.spec = "默认班型";
      this.goodsSpecTable = this.getSpcMergeData(
          data.specificationPriceArr.slice()
        );
      this.onSpecModalOk(
        {
          totalPrice: this.sumTotalPrice(),
          specIdArr: [data.specificationPriceArr[0].id],
          specificationName: this.signUpForm.spec,
          specArr: [],
        },
        data.specificationPriceArr.slice()
      );
    },

    sumTotalPrice() {
      if (this.currentGoods.type === 0) {
        //单品，无折扣价
        return this.goodsSpecTable[0].goodsPrice;
      } else if (this.currentGoods.type === 1) {
        //套餐  goodsDiscountPrice 表示商品总价-优惠价
        return this.goodsSpecTable[0].goodsDiscountPrice;
      }
    },

    // 获取商品规格第二行
    async getGoodsSpBySpType() {
      let params = {
        goodsId: this.currentGoods.goodsId,
      };
      const { data } = await this.$fetch("third_GoodsSpBySpType", params);
      this.specArray = data;
    },

    //更新信息到蓝鲸
    async doLanJingData() {
      let signUpForm = this.signUpForm;
      let trackType = `${this.loginUser.trackType}`;
      let form = {
        mobile: signUpForm.stuMoblie,
        realName: signUpForm.name,
        idCard: signUpForm.idCard,
        //商机 0表示财经，1表示学历
         raceTrack: trackType === '1' ? '0' : trackType === '2' ? '1' : trackType,
         //推送商机，固定值0，表示关闭
         goodsChance: 0,
         //注册渠道
         registerChannelId: trackType === "1" ? "cjxq" : trackType === '2' ? "zkzsd" : 'cjxq',
      };
      if (signUpForm.userId) {
        form.userId = signUpForm.userId;
      }
      let requestName = form.userId ? "updateUserInfo" : "saveUserInfo";
      let result = await this.$fetch(requestName, form);
      if (!form.userId && result) {
        //更新userId
        this.signUpForm.userId = result.data;
      }
      return !!this.signUpForm.userId;
    },

    /***
     * 1、检查表单是否正确
     * 2、检查用户信息是否存在 userId
     * 3、检查商品是否下架
     */
    onSubmit(form) {
      this.$refs[form].validate(async (valid) => {
        if (!valid) return this.$message.error("表单填写错误，请检查！");
        let validatePass = await this.goodsPriceAndUserInfoValidate();
        if (!validatePass) return;
        this.getCreate();
      });
    },

    /**
     * 商品价格以及用户信息的验证
     */
    async goodsPriceAndUserInfoValidate() {
      /***
       * 更新用户信息，或者创建用户信息
       */

      let errMsg = "";
      let hasUserId = await this.doLanJingData();
      if (!hasUserId) {
        errMsg = "用户信息更新蓝鲸失败，用户userId不存在，无法发起报名";
      }

      /***
       * 检查商品是否下架
       */
      if ((await this.checkGoodsStatus()) === 1) {
        errMsg = "商品已下架,请重新选择";
      }

      if (!this.transactionInfo.ncCustomerUserId) {
        errMsg = "成交信息中不存在ncId,不允许创建订单";
      }

      if (errMsg) {
        this.submitLoading = false;
        this.sureVisible = false;
        this.$message.error(errMsg);
      }

      return !errMsg;
    },

    // 生成订单
    async createOrder() {

      // 选择的套餐课程
      let userSelectMealGroupIds =
        this.userSelectMealGroup.map(
          (item) => item.goodsSpecificationPriceId
        ) || [];
      //报考院校
      let goodsEnrollingSchoolList = [
        ...this.graduactionForm.enrollingProvinceIdList.map((item, index) => {
          return {
              volunteer: 1, //第一志愿
              goodsId: this.currentGoods.goodsId,
              name: this.currentGoods.goodsName,
              enrollingProvinceId: item,
              enrollingProvinceName:
                this.graduactionForm.enrollingProvinceNameList[index],
              enrollingSchoolId:
                this.graduactionForm.enrollingSchoolIdList[index],
              enrollingSchoolName:
                this.graduactionForm.enrollingSchoolNameList[index],
            };
        }),
        ...this.graduactionForm.enrollingProvinceIdList.map(() => {
          return {
              volunteer: 2, //第二志愿
              goodsId: this.currentGoods_second.goodsId ? this.currentGoods_second.goodsId : this.currentGoods.goodsId,
              name: this.currentGoods_second.goodsName ? this.currentGoods_second.goodsName : this.currentGoods.goodsName,
              enrollingProvinceId: "",
              enrollingProvinceName: "",
              enrollingSchoolId:
                this.graduactionForm.enrollingSchoolIdList_second[0],
              enrollingSchoolName:
                this.graduactionForm.enrollingSchoolNameList_second[0],
            };
        }),
      ];
      console.log('this.graduactionForm.gradationIdList', this.graduactionForm.gradationIdList);
      //报考专业列表
      let goodsEnrollingMajorList = [
        ...this.graduactionForm.enrollingMajorIdList.map((_, index) => {
          console.log('this.graduactionForm.gradationIdList[index]', this.graduactionForm.gradationIdList[index]);
          return {
            majorId: this.graduactionForm.enrollingMajorIdList[index],
            majorName: this.graduactionForm.enrollingMajorNameList[index],
            gradationId: this.graduactionForm.gradationIdList[index],
          };
        }),
        ...this.graduactionForm.enrollingMajorIdList_second.map((_, index) => {
          console.log('this.graduactionForm.gradationIdList_second[index]', this.graduactionForm.gradationIdList_second[index]);
          return {
            majorId: this.graduactionForm.enrollingMajorIdList_second[index],
            majorName:
              this.graduactionForm.enrollingMajorNameList_second[index],
            gradationId: this.graduactionForm.gradationIdList_second[index],
          };
        }),
      ];

      this.transactionInfo.createId = this.loginUser.tyUserId;

      if(this.wxTradeOrderDiscountVOList.length){
        if( !this.discountPrice ){
          this.discountPrice = 0;
        }
        this.wxTradeOrderDiscountVOList[0].discount = this.discountPrice;
      }

      //协助成交人员
      if(this.transactionInfo.assistDealCrmUserId !== "" && this.transactionInfo.assistDealCrmUserId !== null){
        this.assistDealList.forEach(el => {
          if(el.assistDealCrmUserId === this.transactionInfo.assistDealCrmUserId){
            this.transactionInfo.assistDealNcUserId = el.assistDealNcUserId
            this.transactionInfo.assistDealUserName = el.assistDealUserName
          }
        });
      }

      const params = {
        tyUserId: this.loginUser.tyUserId,
        //优惠活动字段
        wxTradeOrderDiscountVOList: this.wxTradeOrderDiscountVOList,
        goodsId: this.currentGoods.goodsId, //商品id
        userId: this.signUpForm.userId, //用户id
        // businessIdInt: this.currentGoods.businessId,
        commodityIdList: this.isCj ? this.specIdArr : [...this.commodityIdList], //, //商品规格价格id集合
        isFree: 1, // 是否免费，1表示付费
        schoolId: 4, // 平台ID 招生工作台为4
        platformType: 1, // 平台标识 1为web
        //成交校区id   不再需要前端给与网校的默认值。
        signSchoolId: this.transactionInfo.ncCampusSchoolId,
        //成交校区name 不再需要前端给予网校的默认值。
        signSchoolName: this.transactionInfo.campusSchoolName,

        orderType: WOKENBENCH_SIGNUP_TYPE.CREATE, // 1新增报名 2升班报名 3转班报名
        isFormal: 1, // 是否正式订单(0否 1是)
        //商品是不是财经还是学历
        businessIdInt: this.currentGoods.businessId,
        //成考，网教专业
        goodsEnrollingMajorList,
        //成考，网教院校
        goodsEnrollingSchoolList,
        //规格的id
        setCommodityId: this.specIdArr[0],
        //成考的报名年级
        enrollingTime: this.ckSingle.enrollingTime,

        childSpecificationPriceIds: userSelectMealGroupIds.toString(),
        //实际支付价格
        payPrice: this.totalPrice, //实际需付款价格
        totalMoney: this.newGoodsPrice, //实际商品价格
        //学员账号
        phoneNumber: this.signUpForm.stuMoblie,
        //学员姓名
        name: this.signUpForm.name,
        //身份证
        idCard: this.signUpForm.idCard,
        // 学员学历
        education: this.signUpForm.education,
        //全网成交信息
        orderSaleman: JSON.stringify(this.transactionInfo),
        jobLowestSalaryUnderCollege: this.signUpForm.jobLowestSalaryUnderCollege,
        jobLowestSalaryUpCollege: this.signUpForm.jobLowestSalaryUpCollege
      };

      //如果不是财经商品，需要携带 xlType 属性。
      if (!this.isCj) {
        params.xlType = this.xlType;
      }

      //如果是免费商品，则调用免费商品支付接口
      if (this.currentGoods.sellType == 3) {
        params.goodsId = this.currentGoods.goodsId;
        params.childSpecificationPriceIds = this.commodityIdList.toString();
        params.commodityIdListStr = params.commodityIdList.toString();
        let { data, code } = await this.$fetch(
          "thrid_free_createOrder",
          params
        );
        this.submitLoading = false;
        this.sureVisible = false;
        return { data, code };
      }
      console.log('params确认', params);
      //20231110新增入参
      params.crmDeptId = this.loginUser.deptId

      this.processParmas = params;
      //如果是收费商品，则调用收费商品支付接口
      try {
        let { data, code, msg } = await this.$fetch("thrid_createOrder", params);
        return { data, code, msg };
      } catch (e) {
        this.$message.error(e.message);
        return {};
      } finally {
        this.submitLoading = false;
        this.sureVisible = false;
      }
    },

    // 提交成功函数  创建订单
    async getCreate() {
      //创建订单
      try {
        const { data = {}, code, msg } = await this.createOrder();
        if(code == 500) {
          this.$message.error(msg)
          // setTimeout(() => {
          //   this.$router.go(0)
          // }, 1500)
          return false
        }
        // 如果是零元订单检测失败
        if (code == 611) {
          this.tiforList = data
          this.showTis = true
        }
        //判断返回信息
        if (code != 200 || data === null || data === undefined) {
          return this.$message.error("订单生成异常，请联系管理员");
        }

        //商品发生变化的异常信息
        if (data && data.goodsChange) {
          return this.$message.warning(data.goodsChange.msg);
        }

        //是否免费商品
        let isFreePay = this.shouldPay < 0.0001;
        //是否是财经商品
        let isCjGoods = `${this.currentGoods.businessId}` === "1";
        this.toNextPage(isFreePay, isCjGoods, data.orderId);
      } catch (e) {
        this.$message.error("订单生成异常，请联系管理员");
      }
    },

    toNextPage(isFreePay, isCjGoods, orderId) {
      //跳转到收款页面
      let path = "/aftersale/collection";
       if (isFreePay && this.newGoodsPrice == 0) {
        //跳转到学历订单详情
        if(isCjGoods) {
          path = "/afterSale/cjOrderDetail"
        } else {
          path = "/afterSale/xlOrderDetail"
        }
        // path = "/aftersale/payComplete";
      }
      // 非免费课，但应收金额为0，需要跳签约
      if(isFreePay && this.newGoodsPrice != 0) {
        path = "/aftersale/payComplete";
      }
      this.$router.push({ path, query: { orderId, property: this.property} });
    },

    // 检查商品是否下架
    async checkGoodsStatus() {
      const params = {
        goodsId: this.currentGoods.goodsId,
      };
      const { data, code } = await this.$fetch(
        "thrid_checkGoodsStatus",
        params
      );
      if (code === 200) {
        // goodsSchoolSellStatus  0待上架,1下架,2在售
        return data.goodsSchoolSellStatus;
      }
    },

    //根据用户id和商品id获取商品状态判断购没购买
    async findOrderStatus() {
      let ids = this.specIdArr.toString();
      if (!this.signUpForm.userId) return false; //如果userId不存在则直接放行

      const params = {
        userId: this.signUpForm.userId,
        spPriceIdStr: ids,
        enrollingTime: this.isCj?'':this.ckSingle.gradationYear.substring(0, this.ckSingle.gradationYear.length - 2),
      };

      const { data = {} } = await this.$fetch(
        "findOrderStatusByUserIdAndSpecificationId",
        params
      );
      
      if(data.purchaseStatusResultStatus == 8) {
        this.ckMessage = "学员已报名【成考/网教】【学级】班型，请勿重复报名"
        this.repeat = true
        // this.$message.error("学员已报名该班型，请重新选择班型");
        return true
      } else {
        this.repeat = false
      }

      let result = data.purchaseStatusResultStatus !== 0;
      result && this.$message.error("学员已报名该班型，请重新选择班型");
      return result;
    },

    //预览商品
    async previewGoods(goodsId) {
      const params = {
        goodsId: goodsId,
      };
      const { data } = await this.$fetch("previewGoods", params);
      window.open(data.url, "_blank");
    },

    /***
     * 取消按钮的点击事件
     */
    gobackclick() {
      this.$hqMessageBox({
        title: "提示",
        message: "确认取消报名吗?",
      }).then(() => {
        this.$router.back();
      });
    },

    /***
     * 自动通过学员账号查询学员信息
     */
    async getCustomeruser(isNotNotifce) {
      let { stuMoblie, name, idCard } = this.signUpForm;

      //判断手机号是不是没有改变
      if (stuMoblie == this._tmpMoile) {
        return;
      }

      //判断是不是手机号，并且返回。
      if (!this.isPhoneNumber(stuMoblie)) {
        return !isNotNotifce && this.$message.warning("学员账号不正确");
      }

      //根据用户手机号获取用户信息
      let { data = {}, code } = await this.$fetch("getUserByMobile", {
        mobile: stuMoblie,
      });

      //检查学生信息，有就更新，没有就新增
      this._tmpMoile = this.signUpForm.stuMoblie;
      if (data == null || code != 200) {
        this.signUpForm.userId = null;
        return;
      }

      //(1) 如果当前不存在学员姓名，以及idcard,那么直接更新;
      //(1) 如果存在姓名和身份证号，就询问下是否需要覆盖之前信息
      if (name && idCard) {
        this.$hqMessageBox({
          message: "是否替换当前学员信息?",
        }).then(() => {
          //确定更新
          this.updateUserInfo(data);
        });
      } else {
        if (typeof isNotNotifce === "boolean" && isNotNotifce) {
          this.disablePhoneNumber = true;
        }
        this.updateUserInfo(data);
      }
    },

    updateUserInfo(data) {
      Object.assign(this.signUpForm, {
        name: data.realName,
        idCard: data.idCard,
        stuMoblie: data.mobile,
        userId: data.userId,
      });
    },

    /***
     * 获取成交信息
     */
    getTransactionInfo() {
      let form = {
        //商机id
        customerId: this.customerId,
        //来源，表示从哪个页面进来的；如果是商机详情页进来，则sourceId 可以为空。
        sourceId: this.sourceId ? this.sourceId : "0",
        registSource: this.registSource,
      };
      if(this.$route.query.id) {
        form.dealDeptId = this.$route.query.id
      }
      if(this.$route.query.phone) {
        form.phone = this.$route.query.phone
      }
      getTransactionInfo(form).then((res) => {
        if (res.code === 0) {
          this.transactionInfo = res.result || {};
          this.transactionInfo._transIntroduceType = this.transactionInfo.transIntroduceType
          this.transactionInfo.emergencyContact = this.transactionInfo.emergencyContact || null;
          this.transactionInfo.emergencyContactPhone = this.transactionInfo.emergencyContactPhone || null;
          this._getDeptorderId(this.transactionInfo.crmDealSchoolDeptId)
          this.checkIdCard(this.transactionInfo.crmDealSchoolDeptId)
          this.transactionInfo["isRecommandName"] =
            this.transactionInfo["isRecommand"] == 1 ? "是" : "否";
            this.transactionInfo["lecturerAssigned"] == true ? "是" : "否"
          this.transactionInfo.phoneNumber = this.$route.query.phone
            ? this.$route.query.phone
            : this.transactionInfo.phoneNumber;
          //备份相关信息
          this.transactionInfo._isRecommand = this.transactionInfo.isRecommand;
          this.transactionInfo._isRecommandName =
            this.transactionInfo.isRecommandName;
          this.transactionInfo._teacherRecommandName =
            this.transactionInfo.teacherRecommandName;
          this.transactionInfo._ncTeacherRecommandUserId =
            this.transactionInfo.ncTeacherRecommandUserId;
            
          //获取学员手机号码，然后获取学员信息。
          this.signUpForm.stuMoblie = this.transactionInfo.phoneNumber;
          this.transactionInfo["isFourSix"] =
            this.transactionInfo["isFourSix"] == 1 ? 1 : 0;
            // 判断是否从预约报名过来，如果是判断是否46分成为空
           if (this.transactionInfo["isFourSix"] == 0) {
              console.log('进入了这里46分成的判断');
              this.transactionInfo.ncTeacherSalesUserId = null
              this.transactionInfo.crmTeacherSalesUserId = null
              this.transactionInfo.tyUserId = null
              this.transactionInfo.teacherSalesName = null
              this.transactionInfo.teacherSalesDeptName = null
              this.transactionInfo.crmTeacherSalesDeptId = null
              this.transactionInfo.ncTeacherSalesDeptId = null

            }
          //true表示是否在获取信息失败之后要不要报红提示。
          this.getCustomeruser(true);
          //获取协助成交人员列表数据
          this.getAssistDealList()
        }
      });
    },
    // 通过部门拿成交信息
  _getDeptorderId(val) {
    getDeptId(val).then(res => {
      console.log('通过部门拿到成交信息',res);
      this.property = res.result.property
    })
  },
  checkIdCard(crmDealSchoolDeptId){
      checkIdCard(crmDealSchoolDeptId).then(res => {
        console.log('通过部门判断身份证是否必填',res);
        if (res.code === 200) {
          this.isCheckIdCard = res.data;
          if(!this.isCheckIdCard){
            this.signUpFormRules.idCard = [
              { required: false, validator: validate.NotNecessaryIdCard, trigger: "change" },
            ]
          }
        }
      })
    },

 

    /***
     * 获取优惠券列表
     */
    async startActivityDetail(specArr) {
      /***
       * 获取班型的信息，
       * (0) 默认班型
       * (1) 单层单选   [{ specificationNameId1:'',  specificationName1: '' }]
       * (2) 单层多选   [{ specificationNameId1:'',  specificationName1: '' },
       *                { specificationNameId1:'',  specificationName1: '' }, ...]
       * (3) 双层单选   [{ specificationNameId1:'',  specificationName1: '', specificationNameId2:'',  specificationName2: '' }]
       * (4) 双层多选   [{ specificationNameId1:'',  specificationName1: '', specificationNameId2:'',  specificationName2: '' },
       *                { specificationNameId1:'',  specificationName1: '', specificationNameId2:'',  specificationName2: '' }]
       */
      let specificationNameId1 = "";
      let specificationNameIds2 = [];
      if (specArr.length > 1) {
        //多选，可能是(2)(4)情形
        specArr.forEach((item) => {
          let specificationNameId1_temp = item.specificationNameId1;
          let specificationNameId2 = item.specificationNameId2;
          //表示是单层多选情形
          if (!specificationNameId2) {
            specificationNameId1 = this.specTypeArr[0].id;
            specificationNameIds2.push(specificationNameId1_temp);
          } else {
            //表示双层多选，此时的specificationNameId1都是同一个
            specificationNameId1 = item.specificationNameId1;
            specificationNameIds2.push(specificationNameId2);
          }
        });
      } else if( specArr.length == 1 ){
        //单项，则只可能是 (1)(3) 情形
        specificationNameId1 = specArr[0].specificationNameId1;
        let specificationNameId2 = specArr[0].specificationNameId2;
        if (specificationNameId2) {
          specificationNameIds2.push(specificationNameId2);
        }else {
          specificationNameIds2.push(specificationNameId1);
          specificationNameId1= this.specTypeArr[0].id;
        }
      }

      //goodsId, schoolId, specificationNameId1, specificationNameIds2
      let goodsId = this.currentGoods.goodsId;
      let schoolId = this.transactionInfo.ncCampusSchoolId;
      this.findActivityDetail(
        goodsId,
        schoolId,
        specificationNameId1,
        specificationNameIds2
      );
      this.findActivityDetail_notInUserGroup(
        goodsId,
        schoolId,
        specificationNameId1,
        specificationNameIds2
      )
    },
    /***
     * 获取优惠券列表
     */
    async findActivityDetail(
      goodsId,
      schoolId,
      specificationNameId1,
      specificationNameIds2
    ) {
      let formData = {
        goodsId,
        schoolId,
        specificationNameId1,
        specificationNameIds2,
        specificationPriceIds: this.isCj ? this.specIdArr : [...this.commodityIdList],
        userId: this.signUpForm.userId
      };
      this.activedParams = formData
      try {
        let res = await this.$fetch("findActivityDetail", formData);
        if (res.code == 200) {
          let data = res.data || [];
          let dataList = data.map((item) => {
            return {
              activityName: item.activityName,
              isMultiple: item.isMultiple,
              preferentialAmount: item.preferentialAmount,
              endTime: item.endTime,
              id: item.activityId,
              activityId: item.activityId,
              marketInoutConfigSnapshotId: item.inoutConfigSnapshot ? item.inoutConfigSnapshot.id : "",
              inoutConfigSnapshot: item.inoutConfigSnapshot
            };
          });
          this.discountInfoList = dataList;
        }
      } catch (e) {
        console.log(e);
      }
    },
    /***
     * 获取不可用优惠券列表
     */
    async findActivityDetail_notInUserGroup(
      goodsId,
      schoolId,
      specificationNameId1,
      specificationNameIds2
    ) {
      let formData = {
        goodsId,
        schoolId,
        specificationNameId1,
        specificationNameIds2,
        specificationPriceIds: this.isCj ? this.specIdArr : [...this.commodityIdList],
        userId: this.signUpForm.userId
      };
      try {
        let res = await this.$fetch("findActivityDetail_notInUserGroup", formData);
        if (res.code == 200) {
          this.discountNotuse = res.data || [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 身份证号码检验
    idcardBlur() {
      this.$refs.signUpForm.validateField('idCard')
    }
  },

  mounted() {
    //获取customerId，从而获取用户信息
    console.log('报名页面');
    let { customerId, sourceId = "0", registSource } = this.$route.query;
    if (customerId === undefined || customerId === undefined) {
      this.$message.error("商机ID不存在");
      this.$router.back();
    }
    Object.assign(this, { customerId, sourceId, registSource });
    this.getTransactionInfo();
  },

  computed: {
    ...mapGetters(["loginUser"]),

    /***
     * 商品价格
     */
    newGoodsPrice() {
      let goodsSpecTable = this.goodsSpecTable;
      return Array.isArray(goodsSpecTable) && goodsSpecTable.length
        ? goodsSpecTable[0].goodsPrice
        : 0;
    },

    /***
     * 应收金额
     */
    shouldPay() {
      let price = this.newGoodsPrice - this.discountPrice;
      return price.toFixed(2) || "";
    },
  },
  watch: {
    '$route.query.phone': {
      handler(value) {
        if(value) {
          let { customerId, sourceId = "0", registSource } = this.$route.query;
          if (customerId === undefined || customerId === undefined) {
            this.$message.error("商机ID不存在");
            this.$router.back();
          }
          Object.assign(this, { customerId, sourceId, registSource });
          this.getTransactionInfo()
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .Contextclass {
    .v-text-field__details{
      display: flex;
      margin-top: -1.3em;
      pointer-events: none;
    }
  }
.tishiclass {
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #fdebec;
  width: 90%;
  border-radius: 10px;
  line-height: 40px;
  > ul {
    width: 100%;
    > li {
      color: #b61a19;
    }
  }
}
.receiveAble {
  padding-left: 34px;
  margin-top: 10px;
}
.receiveItem {
  padding-left: 20px;
}
.sign-up {
  .breadcrumb-wrap {
    margin-bottom: 18px;
  }
  .fixed-tips {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -30px 30px 0 36px;
    height: 60px;
    border-radius: 4px;
    z-index: 50;
    .fixed-content {
      display: flex;
      align-items: center;
      height: 100%;
      .fixed-icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
        text-align: center;
        border-radius: 50%;
        > i {
          line-height: 24px;
          font-size: 14px;
          color: #fff;
        }
      }
      .fixed-text {
        font-size: 16px;
        color: #333;
      }
      .fixed-line {
        width: 150px;
        height: 1px;
        margin: 0 18px;
        background: #333;
      }
      .fixed-num {
        width: 24px;
        height: 24px;
        color: #333;
        border: 1px solid #333;
        border-radius: 50%;
        text-align: center;
        line-height: 24px;
        margin-right: 12px;
      }
    }
  }
  .on {
    position: fixed;
    width: calc(100% - 228px);
    height: 60px;
    top: 0;
    left: 228px;
  }
  .sub-title {
    margin-bottom: 18px;
    font-size: 14px !important;
    font-weight: 700;
    color: #333;
  }
  .student-area {
    padding-left: 30px;
    padding-top: 18px;
    background: #fff;
    border-radius: 6px;
  }
  .margin-auto {
    width: 94%;
    margin: 0 auto;
  }
  .deal-info-area {
    font-size: 14px;
    min-width: 1000px;
    padding-left: 30px;
    margin-top: 12px;
    padding-top: 18px;
    padding-bottom: 5px;
    background: #fff;
    color: #333;
    border-radius: 6px;
    .deal-info-form {
      margin-left: 15px;
    }
  }
  .goods-area {
    .choice-size {
      background: #fff;
      margin-top: 12px;
      padding: 0 52px 18px 30px;
      border-radius: 6px;
    }
  }
  .choice-course-area {
    margin-bottom: 10px;
    background: #fff;
    margin-top: 10px;
    padding: 0 72px;
    border-radius: 6px;
    .class-table {
      display: flex;
    }
    > h2 {
      margin-top: 24px;
      margin-right: 24px;
      font-size: 14px;
      color: #333;
    }
  }
  .choice-goods-area {
    display: flex;
    margin-bottom: 30px;

    > h2 {
      margin-top: 24px;
      margin-right: 24px;
      color: #333;
    }
    .gift-table-wrapper {
      width: 504px;
    }
  }
  .ticket-area {
    display: flex;
    margin-bottom: 32px;
    padding-left: 34px;
    > h4 {
      margin-top: 7px;
      margin-right: 8px;
      font-size: 14px;
      color: #333;
    }
    .ticket-list {
      display: flex;
      .ticket-item {
        position: relative;
        width: 180px;
        height: 90px;
        margin-right: 12px;
        padding: 12px;
        background: #eff9f7;
        border: 2px solid transparent;
        border-radius: 7px;
        transition: 0.2s;
        cursor: pointer;
        > h4 {
          margin-bottom: 4px;
          color: #3a3c42;
          font-size: 14px;
        }
        .price {
          span {
            &:first-child {
              margin-right: 7px;
              font-size: 22px;
              font-weight: 700;
              color: #337aff;
            }
            &:last-child {
              font-size: 14px;
              color: #337aff;
            }
          }
        }
        .sub-hr {
          width: 100%;
          margin: 0 auto 5px;
          padding: 0 12px;
          border-bottom: 1px dashed #dfdfdf;
        }
        .expiry-date {
          font-size: 12px;
          color: #6c7179;
          text-align: center;
        }
        .select-ok {
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .active {
        border-color: #337aff;
      }
    }
  }

  .loan-field {
    display: flex;
    padding-left: 68px;
    margin-top: 20px;
    line-height: 24px;
    color: #333;
    font-size: 14px;
    h2 {
      margin-right: 36px;
    }
  }

  .total-area {
    display: flex;
    padding-left: 68px;
    margin-top: 20px;
    line-height: 24px;

    .price {
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #606266;
    }
    .coin {
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      // color: #f54955;
      margin: 0 70px 0 18px;
    }
    > h2 {
      font-size: 14px;
      color: #333;
      margin-right: 20px;
    }
    > h3 {
      font-size: 24px;
      font-weight: 700;
      color: #f54955;
    }
  }
  .bottom-area {
    background: #fff;
    // height: 135px;
    padding: 0 30px;
    border-radius: 6px;
    margin-top: 12px;
    .shouleReceive {
      font-size: 14px;
      font-weight: bold;
      // margin-left: 30px;
      color: #333;
    }
    .goods-discount-area {
      .max-discount {
        line-height: 1;
        font-size: 14px;
        color: #333;
      }
      ::v-deep .el-form-item {
        &:first-of-type {
          .el-form-item__label {
            line-height: 1;
          }
        }
      }
    }
  }
  .signup-info {
    // position: absolute;
    // left: 0;
    // top: 85px;
    // bottom: 100px;
    // width: 94%;
    // margin: 0 auto;
    overflow-y: auto;
    padding-bottom: 70px;
  }
  .action-area {
    display: flex;
    background: #fff;
    margin-top: 12px;
    padding: 24px 0;
    width: 100%;
    height: 90px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    position: absolute;
    bottom: 0;
    font-size: 16px;
  }
}
.course-table {
  width: 100%;
  ::v-deep thead .el-checkbox__input {
    display: none;
  }
}
::v-deep .myCell .el-checkbox__input {
  display: none;
}
::v-deep .normal-input {
  width: 192px;
  height: 36px;
  .el-input__suffix {
    right: 12px;
  }
}
::v-deep .stuInfo {
  width: 260px;
  height: 36px;
  .el-input__suffix {
    right: 12px;
  }
}
.idCardBox {
  width: 210px;
  position: relative;
  ::v-deep input {
    border-radius: 6px 0 0 6px;
  }
}
::v-deep .findBtn {
  cursor: pointer;
  position: absolute;
  top: 0px;
  text-align: center;
  width: 50px;
  display: inline-block;
  color: #337aff;
  background: #fff;
  height: 40px;
  border-radius: 0 6px 6px 0;
  border: 1px solid #e1e4eb;
  border-left: none;
  &:hover {
    font-weight: bold;
  }
}
::v-deep .inline-formitem {
  display: inline-block;
  margin-right: 68px;
  width: 100%;
}
::v-deep .idCardBigBox {
  margin-right: 118px;
}
::v-deep .phone {
  width: 260px !important;
}
::v-deep .normal-select {
  width: 192px;
  height: 36px;
}
::v-deep .large-input {
  width: 504px;
  height: 36px;
}

.hr {
  width: 100%;
  border-bottom: 1px dashed #e1e4eb;
  margin: 36px 0;
  padding: 0 18px;
}
.loanclass {
  display: inline-block;
  margin-left: 20px;
  color: red;
}

::v-deep .receiveAble.el-form-item {
  margin-bottom: 0;
}

.litter-title {
  font-size: 13px;
  color: #999;
}

.discount-modal {
  width: 418px;
  height: 94px;
  margin: 0 auto 14px;
  border: 1px solid #E1E1E1;
  padding-top: 12px;

  >div {
    color: #999999;
    font-size: 14px;
    padding-left: 24px;

    &:first-child {
      font-weight: bold;
      margin-bottom: 8px;
    }

    &:last-child {
      line-height: 26px;
      background: #f6f6f6;
      margin-top: 4px;

      >div {
        display: inline-block;

        &:first-child {
          width: 164px;
          position: relative;

          &::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 12px;
            background: #cccccc;
            right: 0;
            top: 7px;
          }
        }

        &:last-child {
          text-align: center;
          width: 226px;
        }
      }
    }
  }
}
</style>
