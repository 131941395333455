var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sign-up" },
    [
      _c(
        "div",
        { staticClass: "signup-info" },
        [
          _c(
            "el-form",
            {
              ref: "signUpForm",
              attrs: {
                model: _vm.signUpForm,
                rules: _vm.signUpFormRules,
                "label-position": "right"
              }
            },
            [
              _c("div", { staticClass: "student-area margin-auto" }, [
                _c("h2", { staticClass: "sub-title" }, [_vm._v("成交信息")]),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _vm._l(_vm.makeList, function(item, index) {
                          return _c(
                            "el-col",
                            {
                              key: index,
                              staticStyle: { "margin-bottom": "20px" },
                              attrs: { span: 4 }
                            },
                            [
                              item.value === "isFourSix"
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      items: [
                                        { id: 1, name: "是" },
                                        { id: 0, name: "否" }
                                      ],
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "是否46分成",
                                      outlined: "",
                                      "item-text": "name",
                                      "item-value": "id",
                                      dense: true,
                                      height: 32,
                                      clearable: true,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto",
                                      "no-data-text": "暂无数据"
                                    },
                                    on: { change: _vm.isfoursixChange },
                                    model: {
                                      value: _vm.transactionInfo[item.value],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.transactionInfo,
                                          item.value,
                                          $$v
                                        )
                                      },
                                      expression: "transactionInfo[item.value]"
                                    }
                                  })
                                : item.value === "lecturerAssigned"
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      items: [
                                        { id: true, name: "是" },
                                        { id: false, name: "否" }
                                      ],
                                      label: "是否外派",
                                      outlined: "",
                                      disabled: "",
                                      "item-text": "name",
                                      "item-value": "id",
                                      dense: true,
                                      height: 32,
                                      clearable: true,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto",
                                      "no-data-text": "暂无数据"
                                    },
                                    model: {
                                      value: _vm.transactionInfo[item.value],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.transactionInfo,
                                          item.value,
                                          $$v
                                        )
                                      },
                                      expression: "transactionInfo[item.value]"
                                    }
                                  })
                                : item.value === "promotionForm"
                                ? _c("v-autocomplete", {
                                    attrs: {
                                      items: [
                                        { id: "招生协助", name: "招生协助" },
                                        { id: "合伙人自主", name: "合伙人自主" }
                                      ],
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "促单形式",
                                      outlined: "",
                                      disabled:
                                        !_vm.transactionInfo[
                                          "economicFlowPartner"
                                        ] &&
                                        !(
                                          _vm.transactionInfo
                                            .channelsTypeName ==
                                          "财经流量合伙人"
                                        ),
                                      "item-text": "name",
                                      "item-value": "id",
                                      dense: true,
                                      height: 32,
                                      clearable: true,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto",
                                      "no-data-text": "暂无数据"
                                    },
                                    model: {
                                      value: _vm.transactionInfo[item.value],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.transactionInfo,
                                          item.value,
                                          $$v
                                        )
                                      },
                                      expression: "transactionInfo[item.value]"
                                    }
                                  })
                                : item.value === "transIntroduceType"
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: item.label,
                                      "hide-details": "auto",
                                      outlined: "",
                                      readonly: "",
                                      dense: true,
                                      height: 32
                                    },
                                    model: {
                                      value:
                                        _vm.recommandTypeMap[
                                          _vm.transactionInfo[item.value]
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.recommandTypeMap,
                                          _vm.transactionInfo[item.value],
                                          $$v
                                        )
                                      },
                                      expression:
                                        "recommandTypeMap[transactionInfo[item.value]]"
                                    }
                                  })
                                : _c("v-text-field", {
                                    attrs: {
                                      label: item.label,
                                      "hide-details": "auto",
                                      outlined: "",
                                      readonly: "",
                                      dense: true,
                                      height: 32
                                    },
                                    model: {
                                      value: _vm.transactionInfo[item.value],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.transactionInfo,
                                          item.value,
                                          $$v
                                        )
                                      },
                                      expression: "transactionInfo[item.value]"
                                    }
                                  })
                            ],
                            1
                          )
                        }),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "margin-bottom": "20px" },
                            attrs: { span: 4 }
                          },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.assistDealList,
                                "menu-props": { bottom: true, offsetY: true },
                                label: "协助成交人员",
                                outlined: "",
                                "item-text": "assistDealUserName",
                                "item-value": "assistDealCrmUserId",
                                dense: true,
                                height: 32,
                                clearable: true,
                                "clear-icon": "$clear",
                                "hide-details": "auto",
                                "no-data-text": "暂无数据",
                                disabled: _vm.transactionInfo.isRecommand == 1
                              },
                              model: {
                                value:
                                  _vm.transactionInfo["assistDealCrmUserId"],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.transactionInfo,
                                    "assistDealCrmUserId",
                                    $$v
                                  )
                                },
                                expression:
                                  "transactionInfo['assistDealCrmUserId']"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "student-area margin-auto" }, [
                _c("h2", { staticClass: "sub-title" }, [
                  _vm._v(" 学员信息"),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        fontweight: "normal",
                        fontsize: "12px",
                        color: "red"
                      }
                    },
                    [
                      _vm._v(
                        " (学习账号用于app的学习与签约接收验证码，请正确填写)"
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 24 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-formitem",
                                attrs: { prop: "stuMoblie" }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    disabled: _vm.disablePhoneNumber,
                                    label: "学习账号",
                                    "hide-details": "auto",
                                    outlined: "",
                                    dense: true,
                                    height: 32
                                  },
                                  on: { blur: _vm.getCustomeruser },
                                  model: {
                                    value: _vm.signUpForm.stuMoblie,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.signUpForm,
                                        "stuMoblie",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "signUpForm.stuMoblie"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-formitem",
                                attrs: { prop: "name" }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "学员姓名",
                                    "hide-details": "auto",
                                    outlined: "",
                                    dense: true,
                                    height: 32
                                  },
                                  model: {
                                    value: _vm.signUpForm.name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.signUpForm,
                                        "name",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "signUpForm.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-formitem idCardBigBox",
                                attrs: { prop: "idCard" }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "身份证号",
                                    "hide-details": "auto",
                                    outlined: "",
                                    dense: true,
                                    height: 32
                                  },
                                  on: { blur: _vm.idcardBlur },
                                  model: {
                                    value: _vm.signUpForm.idCard,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.signUpForm,
                                        "idCard",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "signUpForm.idCard"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-formitem",
                                attrs: { prop: "education" }
                              },
                              [
                                _c("dict-select", {
                                  attrs: { code: "EDUCATION_TYPE" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(props) {
                                        return _c("v-select", {
                                          attrs: {
                                            items: props.dictData,
                                            "menu-props": {
                                              bottom: true,
                                              offsetY: true
                                            },
                                            label: "学员学历",
                                            "item-text": "label",
                                            "item-value": "value",
                                            outlined: "",
                                            dense: true,
                                            height: 32,
                                            clearable: true,
                                            "clear-icon": "$clear",
                                            "hide-details": "auto"
                                          },
                                          model: {
                                            value: _vm.signUpForm.education,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.signUpForm,
                                                "education",
                                                $$v
                                              )
                                            },
                                            expression: "signUpForm.education"
                                          }
                                        })
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-formitem idCardBigBox",
                                attrs: { prop: "emergencyContact" }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "紧急联系人",
                                    "hide-details": "auto",
                                    outlined: "",
                                    dense: true,
                                    height: 42
                                  },
                                  model: {
                                    value: _vm.transactionInfo.emergencyContact,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.transactionInfo,
                                        "emergencyContact",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "transactionInfo.emergencyContact"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "inline-formitem idCardBigBox",
                                attrs: { prop: "emergencyContactPhone" }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    label: "紧急联系人电话",
                                    "hide-details": "auto",
                                    outlined: "",
                                    dense: true,
                                    height: 42
                                  },
                                  model: {
                                    value:
                                      _vm.transactionInfo.emergencyContactPhone,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.transactionInfo,
                                        "emergencyContactPhone",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "transactionInfo.emergencyContactPhone"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "line" }),
              _c("div", { staticClass: "goods-area margin-auto" }, [
                _c(
                  "section",
                  { staticClass: "choice-size" },
                  [
                    _c("h2", { staticClass: "sub-title" }, [
                      _vm._v("商品信息")
                    ]),
                    _c(
                      "el-row",
                      { attrs: { gutter: 8 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "goodsName" } },
                              [
                                _c("v-text-field", {
                                  staticStyle: {
                                    width: "100%",
                                    display: "inline-block"
                                  },
                                  attrs: {
                                    label: "选择商品",
                                    "hide-details": "auto",
                                    outlined: "",
                                    dense: true,
                                    height: 32,
                                    readonly: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.onChoiceGoods.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.signUpForm.goodsName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.signUpForm, "goodsName", $$v)
                                    },
                                    expression: "signUpForm.goodsName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            staticStyle: { "line-height": "40px" },
                            attrs: { span: 8 }
                          },
                          [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.currentGoods.statusOnlineSell == 2,
                                    expression:
                                      "currentGoods.statusOnlineSell == 2"
                                  }
                                ],
                                staticStyle: { margin: "0 12px" },
                                attrs: { type: "error" },
                                on: {
                                  click: function($event) {
                                    return _vm.previewGoods(
                                      _vm.currentGoods.goodsId
                                    )
                                  }
                                }
                              },
                              [_vm._v("预览")]
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  type: "error",
                                  disabled: !_vm.signUpForm.goodsName
                                },
                                on: { click: _vm.removeChoiceGoods }
                              },
                              [_vm._v("重置")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    this.ctr.showCkSingleOption
                      ? [
                          _vm.showYxOption == true
                            ? _c(
                                "el-row",
                                { attrs: { gutter: 8 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("schooleTree", {
                                        ref: "deptsys",
                                        attrs: { data: _vm.findSchoolList },
                                        on: {
                                          "update:select": _vm.selectSchoolNcid
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("br"),
                          _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _vm.showYxOption == true
                                ? _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "12px"
                                          },
                                          attrs: { prop: "enrollingMajorId" }
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "normal-select",
                                            staticStyle: {
                                              width: "250px",
                                              "margin-right": "12px"
                                            },
                                            attrs: {
                                              items: this.ckSingle
                                                .enrollingMajorList,
                                              "menu-props": {
                                                bottom: true,
                                                offsetY: true
                                              },
                                              label: "报考专业",
                                              outlined: "",
                                              "item-text": "majorName",
                                              "item-value": "majorId",
                                              dense: true,
                                              height: 32,
                                              clearable: true,
                                              "clear-icon": "$clear",
                                              "hide-details": "auto",
                                              "no-data-text": "暂无数据"
                                            },
                                            on: {
                                              change:
                                                _vm.selectCkSingleProfessional
                                            },
                                            model: {
                                              value:
                                                _vm.ckSingle.form
                                                  .enrollingMajorId,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.ckSingle.form,
                                                  "enrollingMajorId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ckSingle.form.enrollingMajorId"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.showYxOption == false
                            ? _c(
                                "el-form-item",
                                [
                                  _c("v-text-field", {
                                    staticStyle: { width: "250px" },
                                    attrs: {
                                      label: "报考院校",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      readonly: ""
                                    },
                                    model: {
                                      value:
                                        _vm.currentGoods.enrollingSchoolName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.currentGoods,
                                          "enrollingSchoolName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentGoods.enrollingSchoolName"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _vm.showYxOption == false
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "12px" },
                                      attrs: { prop: "enrollingMajorId" }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        staticClass: "normal-select",
                                        staticStyle: {
                                          width: "250px",
                                          "margin-right": "12px"
                                        },
                                        attrs: {
                                          items: this.ckSingle
                                            .enrollingMajorList,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true
                                          },
                                          label: "报考专业",
                                          outlined: "",
                                          "item-text": "majorName",
                                          "item-value": "id",
                                          dense: true,
                                          height: 32,
                                          clearable: true,
                                          "clear-icon": "$clear",
                                          "hide-details": "auto",
                                          "no-data-text": "暂无数据"
                                        },
                                        on: {
                                          change: _vm.selectCkSingleProfessional
                                        },
                                        model: {
                                          value:
                                            _vm.ckSingle.form.enrollingMajorId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ckSingle.form,
                                              "enrollingMajorId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ckSingle.form.enrollingMajorId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "12px" },
                                  attrs: { prop: "enrollingGradationId" }
                                },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "normal-select",
                                    staticStyle: { width: "250px" },
                                    attrs: {
                                      items: this.ckSingle
                                        .enrollingGradationList,
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "报考学级",
                                      outlined: "",
                                      "item-text": "gradationYear",
                                      "item-value": "id",
                                      dense: true,
                                      height: 32,
                                      clearable: true,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto",
                                      "no-data-text": "暂无数据"
                                    },
                                    on: { change: _vm.selectCkSingleGradation },
                                    model: {
                                      value:
                                        _vm.ckSingle.form.enrollingGradationId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ckSingle.form,
                                          "enrollingGradationId",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ckSingle.form.enrollingGradationId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.repeat
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "red",
                                    "padding-bottom": "10px"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.ckMessage))]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _vm.ckSingle.goodsPrice
                                ? _c(
                                    "el-form-item",
                                    [
                                      _c("v-text-field", {
                                        staticStyle: { width: "250px" },
                                        attrs: {
                                          label: "商品价格(元)",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.ckSingle.goodsPrice,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.ckSingle,
                                              "goodsPrice",
                                              $$v
                                            )
                                          },
                                          expression: "ckSingle.goodsPrice"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.ckSingle.effectiveMonth
                                ? _c(
                                    "el-form-item",
                                    { staticStyle: { "margin-left": "12px" } },
                                    [
                                      _vm.ckSingle.effectiveMonth
                                        ? _c("v-text-field", {
                                            staticStyle: { width: "250px" },
                                            attrs: {
                                              value:
                                                _vm.ckSingle.effectiveMonth,
                                              label: "学习有效期(月)",
                                              "hide-details": "auto",
                                              outlined: "",
                                              dense: true,
                                              height: 32,
                                              readonly: ""
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          (_vm.currentGoods.xlType != 3
                          ? _vm.xlType == 2
                            ? _vm.ckSingle.courseList.length
                            : true
                          : false)
                            ? _c(
                                "el-form-item",
                                {
                                  staticClass: "choiceClass",
                                  attrs: { prop: "choiceClass" }
                                },
                                [
                                  _c("h2", [_vm._v("课程清单")]),
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "800px" },
                                      attrs: {
                                        border: "",
                                        stripe: "",
                                        size: "mini",
                                        "element-loading-text": "拼命加载中",
                                        "row-style": { height: "20px" },
                                        "cell-style": { padding: "0px" },
                                        "header-cell-style": {
                                          background: "#e5f2ff"
                                        },
                                        "highlight-current-row": "",
                                        "header-cell-class-name":
                                          "normal-table-header",
                                        data: _vm.ckSingle.courseList
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: "课程",
                                          prop: "courseName"
                                        }
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: "类型",
                                          prop: "type"
                                        }
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          "show-overflow-tooltip": true,
                                          label: "上课形式",
                                          prop: "classForm",
                                          width: "125"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.xlType == 2
                            ? [
                                _c("el-form-item", [
                                  _c("h2", [_vm._v("第二志愿（选填）")])
                                ]),
                                _vm.disabledsp
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { prop: "goodsName" } },
                                      [
                                        _c("v-text-field", {
                                          staticStyle: { width: "500px" },
                                          attrs: {
                                            label: "选择商品",
                                            "hide-details": "auto",
                                            outlined: "",
                                            dense: true,
                                            height: 32,
                                            readonly: ""
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.onChoiceGoods_second.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.currentGoods_second.goodsName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.currentGoods_second,
                                                "goodsName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentGoods_second.goodsName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showYxOption2 == true
                                  ? _c(
                                      "el-row",
                                      { attrs: { gutter: 24 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 8 } },
                                          [
                                            _c("schooleTree", {
                                              ref: "deptsystwo",
                                              attrs: {
                                                data: _vm.findtwoSchoolList
                                              },
                                              on: {
                                                "update:select":
                                                  _vm.selectSchoolNcidtwo
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("br"),
                                _vm.showYxOption2 == false
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c("v-text-field", {
                                          staticStyle: { width: "500px" },
                                          attrs: {
                                            label: "报考院校",
                                            "hide-details": "auto",
                                            outlined: "",
                                            dense: true,
                                            height: 32,
                                            readonly: ""
                                          },
                                          model: {
                                            value:
                                              _vm.currentGoods_second
                                                .enrollingSchoolName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.currentGoods_second,
                                                "enrollingSchoolName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentGoods_second.enrollingSchoolName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showYxOption2 == true
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { prop: "enrollingMajorId" } },
                                      [
                                        _c("v-autocomplete", {
                                          staticClass: "normal-select",
                                          staticStyle: {
                                            width: "500px",
                                            "margin-right": "12px"
                                          },
                                          attrs: {
                                            items: this.ckSingle
                                              .enrollingMajorList_second,
                                            "menu-props": {
                                              bottom: true,
                                              offsetY: true
                                            },
                                            label: "报考 专业",
                                            outlined: "",
                                            "item-text": "majorName",
                                            "item-value": "majorId",
                                            dense: true,
                                            height: 32,
                                            clearable: true,
                                            "clear-icon": "$clear",
                                            "hide-details": "auto",
                                            "no-data-text": "暂无数据"
                                          },
                                          on: {
                                            change:
                                              _vm.selectCkSingleProfessional_second
                                          },
                                          model: {
                                            value:
                                              _vm.ckSingle.form
                                                .enrollingMajorId_second,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ckSingle.form,
                                                "enrollingMajorId_second",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ckSingle.form.enrollingMajorId_second"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showYxOption2 == false
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { prop: "enrollingMajorId" } },
                                      [
                                        _c("v-autocomplete", {
                                          staticClass: "normal-select",
                                          staticStyle: {
                                            width: "500px",
                                            "margin-right": "12px"
                                          },
                                          attrs: {
                                            items: this.ckSingle
                                              .enrollingMajorList_second,
                                            "menu-props": {
                                              bottom: true,
                                              offsetY: true
                                            },
                                            label: "报考专 业",
                                            outlined: "",
                                            "item-text": "majorName",
                                            "item-value": "id",
                                            dense: true,
                                            height: 32,
                                            clearable: true,
                                            "clear-icon": "$clear",
                                            "hide-details": "auto",
                                            "no-data-text": "暂无数据"
                                          },
                                          on: {
                                            change:
                                              _vm.selectCkSingleProfessional_second
                                          },
                                          model: {
                                            value:
                                              _vm.ckSingle.form
                                                .enrollingMajorId_second,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.ckSingle.form,
                                                "enrollingMajorId_second",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "ckSingle.form.enrollingMajorId_second"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ]
                      : _vm._e(),
                    this.ctr.showCjSingleOption
                      ? [
                          _c(
                            "el-row",
                            { attrs: { gutter: 8 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { prop: "spec" }
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          label: "选择班型",
                                          "hide-details": "auto",
                                          outlined: "",
                                          dense: true,
                                          height: 32,
                                          readonly: ""
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.onChoiceGoodsSpec.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.signUpForm.spec,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.signUpForm,
                                              "spec",
                                              $$v
                                            )
                                          },
                                          expression: "signUpForm.spec"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.repeat
                                ? _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "padding-bottom": "10px"
                                        }
                                      },
                                      [_vm._v("学员已报名该班型，请勿重复报名")]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.employmentShow
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#333"
                                    }
                                  },
                                  [_vm._v("就业保底底金")]
                                ),
                                _c(
                                  "el-row",
                                  {
                                    staticStyle: { "margin-top": "12px" },
                                    attrs: { gutter: 8 }
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop:
                                                "jobLowestSalaryUnderCollege"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                label: "大专以下(元/月)",
                                                "hide-details": "auto",
                                                outlined: "",
                                                dense: true,
                                                height: 32
                                              },
                                              model: {
                                                value:
                                                  _vm.signUpForm
                                                    .jobLowestSalaryUnderCollege,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.signUpForm,
                                                    "jobLowestSalaryUnderCollege",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "signUpForm.jobLowestSalaryUnderCollege"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 4 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              prop: "jobLowestSalaryUpCollege"
                                            }
                                          },
                                          [
                                            _c("v-text-field", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                label: "大专及大专以上(元/月)",
                                                "hide-details": "auto",
                                                outlined: "",
                                                dense: true,
                                                height: 32
                                              },
                                              model: {
                                                value:
                                                  _vm.signUpForm
                                                    .jobLowestSalaryUpCollege,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.signUpForm,
                                                    "jobLowestSalaryUpCollege",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "signUpForm.jobLowestSalaryUpCollege"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _vm.currentGoods.goodsTemplate != 6
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "specTable" } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        border: "",
                                        stripe: "",
                                        size: "mini",
                                        "element-loading-text": "拼命加载中",
                                        "row-style": { height: "20px" },
                                        "cell-style": { padding: "0px" },
                                        "header-cell-style": {
                                          background: "#e5f2ff"
                                        },
                                        "highlight-current-row": "",
                                        "header-cell-class-name":
                                          "normal-table-header",
                                        data: _vm.goodsSpecTable
                                      }
                                    },
                                    [
                                      _vm._l(_vm.goodsColumns, function(item) {
                                        return [
                                          item.prop === "goodsDiscountPrice"
                                            ? _c("el-table-column", {
                                                key: item.prop,
                                                attrs: {
                                                  "show-overflow-tooltip": true,
                                                  prop: item.prop,
                                                  label: item.label
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var row = ref.row
                                                        return [
                                                          row.goodsDiscountPrice !=
                                                            "" &&
                                                          row.goodsDiscountPrice !=
                                                            "--" &&
                                                          row.goodsDiscountPrice !=
                                                            undefined
                                                            ? _c("p", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      (
                                                                        row.goodsPrice -
                                                                        row.goodsDiscountPrice
                                                                      ).toFixed(
                                                                        2
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            : _c("p", [
                                                                _vm._v("0.00")
                                                              ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            : _c("el-table-column", {
                                                key: item.label,
                                                attrs: {
                                                  "show-overflow-tooltip": true,
                                                  width: item.width || "120px",
                                                  label: item.label,
                                                  prop: item.prop
                                                }
                                              })
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentGoods.goodsTemplate == 6
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "specTable" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { color: "#666" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "商品价格: ¥" +
                                            _vm._s(
                                              (_vm.goodsSpecTable[0] &&
                                                _vm.goodsSpecTable[0]
                                                  .goodsPrice) ||
                                                0.0
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.currentGoods.goodsTemplate == 6
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "specTable" } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        border: "",
                                        stripe: "",
                                        size: "mini",
                                        "element-loading-text": "拼命加载中",
                                        "row-style": { height: "20px" },
                                        "cell-style": { padding: "0px" },
                                        "header-cell-style": {
                                          background: "#e5f2ff"
                                        },
                                        "highlight-current-row": "",
                                        "header-cell-class-name":
                                          "normal-table-header",
                                        data: _vm.comComGoodsInfoList
                                      }
                                    },
                                    [
                                      _vm._l(_vm.cjComComGoodsColumns, function(
                                        item
                                      ) {
                                        return [
                                          _c("el-table-column", {
                                            key: item.label,
                                            attrs: {
                                              "show-overflow-tooltip": true,
                                              width: item.width || "120px",
                                              label: item.label,
                                              prop: item.prop
                                            }
                                          })
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "choiceClass",
                              attrs: { prop: "choiceClass" }
                            },
                            [
                              _c("span", { staticClass: "litter-title" }, [
                                _vm._v("课程清单")
                              ]),
                              _c("selcetionClass", {
                                ref: "selcetionClass",
                                attrs: {
                                  mealGroupData: _vm.mealGroupData,
                                  type: _vm.currentGoods.type,
                                  oneItem: _vm.oneItem
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                { staticClass: "bottom-area margin-auto" },
                [
                  _c("h2", { staticClass: "shouleReceive" }, [
                    _vm._v("收款信息")
                  ]),
                  _c(
                    "el-row",
                    {
                      staticStyle: { "padding-top": "20px" },
                      attrs: { gutter: 24 }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.newGoodsPrice,
                              label: "商品价格",
                              "hide-details": "auto",
                              outlined: "",
                              dense: true,
                              height: 32,
                              readonly: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { margin: "20px 0" },
                          attrs: { span: 24 }
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      items: _vm.discountInfoList,
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true
                                      },
                                      label: "商品优惠",
                                      outlined: "",
                                      "item-text": "activityName",
                                      "item-value": "id",
                                      dense: true,
                                      height: 32,
                                      clearable: true,
                                      "clear-on-select": true,
                                      "clear-icon": "$clear",
                                      "hide-details": "auto",
                                      "no-data-text": "暂无数据"
                                    },
                                    on: { change: _vm.selectDiscountInfo },
                                    model: {
                                      value: _vm.discountType,
                                      callback: function($$v) {
                                        _vm.discountType = $$v
                                      },
                                      expression: "discountType"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 4 } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "商品优惠金额",
                                      disabled: !_vm.wxTradeOrderDiscountVOList
                                        .length,
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: true,
                                      height: 32
                                    },
                                    on: { input: _vm.inputDiscountPrice },
                                    model: {
                                      value: _vm.discountPrice,
                                      callback: function($$v) {
                                        _vm.discountPrice = $$v
                                      },
                                      expression: "discountPrice"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.discountNotuse.length
                                ? _c("el-col", { attrs: { span: 4 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "line-height": "32px",
                                          fontsize: "14px",
                                          color: "#f00",
                                          cursor: "pointer"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.disountsShow = true
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-warning-outline"
                                        }),
                                        _vm._v("不可用优惠 ")
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm.wxTradeOrderDiscountVOList.length
                                ? _c("el-col", { attrs: { span: 24 } }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          height: "24px",
                                          fontsize: "14px",
                                          color: "#f00"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.discountTip) + " "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      value: _vm.shouldPay,
                                      label: "应收金额(元)",
                                      "hide-details": "auto",
                                      outlined: "",
                                      dense: true,
                                      height: 32,
                                      readonly: ""
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                {
                  staticClass:
                    "colllection-info-wrap choice-course-area receiveItem"
                },
                [
                  _c("h2", { staticClass: "sub-title" }, [_vm._v("收费项目")]),
                  _c(
                    "section",
                    { staticClass: "colllectionInfoBox" },
                    [
                      _c("receiveItemTable", {
                        attrs: {
                          columns: _vm.receiveItemColumns,
                          tableData: _vm.receiveItemData,
                          showSummary: true,
                          summaryMethod: _vm.receiveItemSummaryMethod
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "action-area" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "medium", gap: 18 },
              on: { click: _vm.gobackclick }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "medium",
                type: "primary",
                disabled: !_vm.currentGoods.goodsId
              },
              on: {
                click: function($event) {
                  return _vm.showSureData("signUpForm")
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c("goods-modal1", {
        attrs: {
          visible: _vm.goodsDrawerVisible,
          goodsForm: _vm.goodsForm,
          selectGoodsType: "2",
          goodsFormRules: _vm.goodsFormRules,
          goodsFormItems: _vm.goodsFormItems,
          schoolId: this.transactionInfo.ncCampusSchoolId,
          type: "signup"
        },
        on: {
          "update:visible": function($event) {
            _vm.goodsDrawerVisible = $event
          },
          "on-ok": _vm.onGoodsDrawerOk
        }
      }),
      _c("goods-modal1", {
        attrs: {
          visible: _vm.goodsDrawerVisible_second,
          goodsForm: _vm.goodsForm,
          goodsFormRules: _vm.goodsFormRules,
          goodsFormItems: _vm.goodsFormItems,
          businessId: _vm.currentGoods.businessId,
          selectGoodsType: "5",
          enrollingMajorId: _vm.graduactionForm.enrollingMajorIdList[0],
          enrollingGradation: _vm.ckSingle.enrollingTime,
          enrollingProductId: _vm.currentGoods.productId,
          type: "signup"
        },
        on: {
          "update:visible": function($event) {
            _vm.goodsDrawerVisible_second = $event
          },
          "on-ok": _vm.onGoodsDrawerOk_second
        }
      }),
      _c("goods-specs-modal1", {
        attrs: {
          visible: _vm.gsVisible,
          specTypeArr: _vm.specTypeArr,
          specPriceArr: _vm.specPriceArr,
          specArray: _vm.specArray,
          currentGoodsRank: _vm.currentGoodsRank,
          specCountArr: _vm.specCountArr
        },
        on: {
          "update:specPriceArr": function($event) {
            _vm.specPriceArr = $event
          },
          "update:spec-price-arr": function($event) {
            _vm.specPriceArr = $event
          },
          "on-cancel": _vm.onGsCancel,
          onSpecModalOk: _vm.onSpecModalOk
        }
      }),
      _c("confirm-order-modal1", {
        attrs: {
          visible: _vm.showConfirmModal,
          goodsPic: _vm.currentGoods.smallPic,
          goodsName: _vm.currentGoods.goodsName,
          specificationName: _vm.signUpForm.spec,
          orderMoney: _vm.totalPrice
        },
        on: {
          "on-ok": _vm.getCreate,
          "on-close": function($event) {
            return _vm.handleConfirmModal(false)
          }
        }
      }),
      _c("sure1", {
        attrs: {
          visible: _vm.sureVisible,
          showTitle: "确认报名单",
          sureData: _vm.sureData,
          loading: _vm.submitLoading
        },
        on: { "on-cancel": _vm.onSureCancel, "on-ok": _vm.onSureOk }
      }),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.disountsShow,
            title: "不可用优惠",
            width: "480"
          },
          on: {
            "on-cancel": function($event) {
              _vm.disountsShow = false
            }
          }
        },
        [
          _vm._l(_vm.discountNotuse, function(item) {
            return _c("div", { key: item.id, staticClass: "discount-modal" }, [
              _c("div", [_vm._v(_vm._s(item.activityName))]),
              _c("div", [
                _vm._v("优惠¥0.00~ ¥" + _vm._s(item.preferentialAmount) + ".00")
              ]),
              _c("div", [
                _c("div", [_vm._v("学员不符合优惠条件")]),
                _c("div", [_vm._v(_vm._s(item.endTime) + " 过期")])
              ])
            ])
          }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.disountsShow = false
                    }
                  }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.showTis, title: "提交失败", width: "480" },
          on: { "on-cancel": _vm.tijiaoProcancel }
        },
        [
          _c("div", [
            _c("i", { attrs: { icon: "el-icon-warning", type: "danger" } }),
            _vm._v("失败原因")
          ]),
          _c(
            "div",
            { staticClass: "tishiclass" },
            _vm._l(_vm.tiforList, function(item, index) {
              return _c("ul", { key: index }, [
                _c("li", [_vm._v(_vm._s(item))])
              ])
            }),
            0
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.lingyuanForm,
                rules: _vm.lingyuanFormRules,
                "label-position": "right"
              }
            },
            [
              _c(
                "el-form-item",
                { staticClass: "Contextclass", attrs: { prop: "lingyuan" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      "hide-details": "auto",
                      label: "开通零元课程原因",
                      placeholder: "请输入开通零元课程原因,500字以内",
                      outlined: "",
                      dense: true,
                      height: 120,
                      maxlength: "500",
                      counter: ""
                    },
                    model: {
                      value: _vm.lingyuanForm.lingyuan,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.lingyuanForm,
                          "lingyuan",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "lingyuanForm.lingyuan"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: { click: _vm.tijiaoProcancel }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.tijiaoProadd }
                },
                [_vm._v("提交审批")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }